import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import styled from "styled-components";

const Container = styled(CircularProgressbar)`
  .CircularProgressbar-trail {
    stroke: url(#gradient-bg) !important;
  }

  .CircularProgressbar-text {
    fill: url(#gradient-text) !important;
    font-size: 30px !important;
  }
`;

function GradientSVG({
  startColor,
  endColor,
  idCSS,
  rotation,
}: {
  startColor: string;
  endColor: string;
  idCSS: string;
  rotation: string;
}) {
  let gradientTransform = `rotate(${rotation})`;
  return (
    <svg style={{ height: 0 }}>
      <defs>
        <linearGradient id={idCSS} gradientTransform={gradientTransform}>
          <stop offset="0%" stopColor={startColor} />
          <stop offset="100%" stopColor={endColor} />
        </linearGradient>
      </defs>
    </svg>
  );
}
function GredientCircularProgress({
  value,
  maxValue,
}: {
  value: number;
  maxValue: number;
}) {
  return (
    <>
      <Container value={value} maxValue={maxValue} text={`${value}`} />
      <GradientSVG
        startColor={value <= 3 ? "#FF5757" : "#E3FAFF"}
        endColor={value <= 3 ? "#FF5757" : "#53CAF5"}
        rotation="0"
        idCSS={"gradient"}
      />
      <GradientSVG
        startColor={value <= 3 ? "#FF5757" : "#E3FAFF"}
        endColor={value <= 3 ? "#FF5757" : "#53CAF5"}
        rotation="0"
        idCSS={"gradient-text"}
      />
      <GradientSVG
        startColor={value <= 3 ? "#EFF0F5" : "rgba(239, 240, 245, 1)"}
        endColor={value <= 3 ? "#EFF0F5" : "#E3FAFF"}
        rotation="0"
        idCSS={"gradient-bg"}
      />
    </>
  );
}

export default GredientCircularProgress;

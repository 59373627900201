import React, { useEffect, useRef, useState } from "react";
import LandscapeProgramLayout from "../LandscapeProgramLayout";
import BottomNav from "../BottomNav";
import cn from "../../../utils/cn";
import { Pause, Triangle } from "../../Icons";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useAuthContext } from "../../../context/auth";
import EventEmitter from "eventemitter3";
import dayjs from "dayjs";
import useCountDown from "../../../hooks/useCountDown";

function interpolate(value: number): number {
  return (value * (100 - 10)) / 100 + 10;
}

function Line() {
  return (
    <svg
      width="4"
      height="92"
      viewBox="0 0 4 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="2"
        y1="-8.74228e-08"
        x2="2"
        y2="92"
        stroke="url(#paint0_linear_5264_1049)"
        stroke-width="4"
        stroke-dasharray="6 6"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5264_1049"
          x1="0"
          y1="0"
          x2="-1.99976"
          y2="0.0217366"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E3FAFF" />
          <stop offset="1" stop-color="#53CAF5" />
        </linearGradient>
      </defs>
    </svg>
  );
}

function Fit({
  active,
  callback,
  number,
}: {
  number: number;
  active: boolean;
  callback: () => void;
}) {
  const time = 120;
  const [count, setCount] = useState(0);
  const isCompleted = useRef(false);

  useEffect(() => {
    if (active && !isCompleted.current) {
      const timer = setInterval(() => {
        setCount((prev) => {
          if (prev === time) {
            callback();
            isCompleted.current = true;
            clearInterval(timer);
            return prev;
          }
          return prev + 1;
        });
      }, 1000);
      return () => {
        clearInterval(timer);
      };
    } else {
    }
  }, [active]);

  return (
    <div className="gap-[50px] flex items-center">
      <h1 className="text-[48px] font-bold text-white">{number}FIT</h1>
      <div
        className="w-[1000px] h-[100px] rounded-[100px] flex items-center justify-center relative"
        style={{
          border: "4px solid transparent",
          backgroundImage: "linear-gradient(135deg, #E3FAFF 0%, #53CAF5 100%)",
          backgroundOrigin: "border-box",
          // backgroundClip: "content-box, border-box",
        }}
      >
        <div className="absolute top-0 left-1/2 -translate-x-1/2">
          <Line />
        </div>
        <div className="w-full h-full bg-bg rounded-[100px] flex items-center">
          <div
            className="w-[984px] h-[84px] rounded-[84px] flex justify-end mx-2 relative"
            style={{
              backgroundImage:
                "linear-gradient(270deg, #53CAF5 0%, rgba(44, 218, 0, 0.00) 100%)",
              transition: "all 0.5s ease",
              width: `${interpolate((count / time) * 100)}%`,
              minWidth: "84px",
            }}
          >
            <div
              className="w-[84px] h-[84px] rounded-[100px]"
              style={{
                backgroundImage:
                  "linear-gradient(135deg, #E3FAFF 0%, #53CAF5 100%)",
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}

function WorkoutType1({ secondRound }: { secondRound?: boolean }) {
  const {
    emitter,
    sendSetStep,
    step,
    startTime,
    sendStartCountDown,
    sendStopCountDown,
  } = useAuthContext();
  const [activeNumber, setActiveNumber] = useState(1);
  const { count, reset, start, stop, stopped } = useCountDown(
    480,
    () => {
      sendSetStep(step + 1);
    },
    true
  );
  useEffect(() => {
    setTimeout(() => {
      start();
    }, startTime.current - dayjs().valueOf());

    emitter.current.on("resume", (startTime) => {
      setTimeout(() => {
        start();
      }, startTime - dayjs().valueOf());
    });
    emitter.current.on("pause", (startTime) => {
      setTimeout(() => {
        stop();
      }, startTime - dayjs().valueOf());
    });
    return () => {
      emitter.current.off("resume");
      emitter.current.off("pause");
    };
  }, []);

  return (
    <LandscapeProgramLayout step={4}>
      <>
        <div className="bg-bg w-full relative h-full flex">
          <div className="flex flex-col gap-[50px] ml-[59px] flex-1 justify-center">
            {Array(4)
              .fill(0)
              .map((_, i) => (
                <Fit
                  active={i <= activeNumber - 1 && !stopped}
                  callback={() => {
                    if (i === 3) {
                      return;
                    } else {
                      setActiveNumber((prev) => prev + 1);
                    }
                  }}
                  number={i + 1}
                  key={i}
                />
              ))}
          </div>
          <div className="absolute left-[1440px] top-1/2 -translate-y-1/2 flex flex-col items-center w-[420px]">
            <h2 className="text-[80px] font-bold text-white">
              {secondRound ? 2 : 1}/2R
            </h2>
            <h2 className="text-[140px] font-bold text-white">
              {String(Math.floor(count / 60)).padStart(2, "0")}:
              {String(count % 60).padStart(2, "0")}{" "}
            </h2>
            <button
              onClick={() => {
                if (stopped) {
                  sendStartCountDown();
                } else {
                  sendStopCountDown();
                }
              }}
            >
              <Pause />
            </button>
          </div>
        </div>
      </>
      <BottomNav />
    </LandscapeProgramLayout>
  );
}

export default WorkoutType1;

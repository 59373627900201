import React from "react";
import { LogoutIcon } from "./Icons";
import { useAuthContext } from "../context/auth";

function PortraitDefault() {
  const { logout } = useAuthContext();
  return (
    <div
      className="flex justify-center items-center h-full w-full relative"
      style={{
        background:
          "var(--GMOHOB2, linear-gradient(135deg, #E3FAFF 0%, #53CAF5 100%))",
      }}
    >
      <button className="absolute top-0 right-0 m-10" onClick={() => logout()}>
        <LogoutIcon className="w-[60px] h-[60px]" />
      </button>
      <img
        src={"/logo2.png"}
        className="w-[786px] h-[386px] absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
        alt="logo"
      />
      <p className="text-[#48505F] text-[32px] absolute bottom-0">
        Premium Training Place
      </p>
    </div>
  );
}

export default PortraitDefault;

import React, { useEffect } from "react";
import LandscapeProgramLayout from "../LandscapeProgramLayout";
import cn from "../../../utils/cn";
import { useAuthContext } from "../../../context/auth";
import BottomNav from "../BottomNav";
import { TimerRenderer, useTimer } from "react-use-precision-timer";
import { ReservationsDetailData } from "../../../Api";
import api from "../../../lib/api";

const MINI_CHARACTERS = [
  "/characters/1_mini.png",
  "/characters/2_mini.png",
  "/characters/3_mini.png",
  "/characters/4_mini.png",
];

function LandscapeTeamRow() {
  const [reservations, setReservations] =
    React.useState<ReservationsDetailData>([]);

  const {
    did,
    row,
    classId,
    selectedUserIdsByRow,
    sendHandleSelectedUserIdsByRow,
  } = useAuthContext();

  useEffect(() => {
    if (did === "0") {
      if (classId) {
        api.fits.reservationsDetail(classId).then((res) => {
          setReservations(res.data);
        });
      }
    }
  }, [classId, did]);

  if (row === undefined) {
    return <div>Loading...</div>;
  }

  return (
    <LandscapeProgramLayout step={5}>
      <div className="h-full w-full flex relative">
        <div className="p-[60px] flex flex-col gap-[80px] items-center w-full">
          <div className="flex items-center justify-between w-full">
            <h1 className="gradient-text text-[70px] font-bold">TEAM 설정</h1>
            <div className="flex items-center gap-7">
              <p className="text-[48px] font-bold text-white">{row}</p>
              <p className="text-[48px] text-white font-bold">로잉</p>
            </div>
          </div>
          <div className="flex gap-[40px] w-full">
            {MINI_CHARACTERS.map((character, row) => (
              <div
                key={row}
                className="flex flex-col items-center gap-[30px] flex-1"
              >
                <img src={character} alt="character" className="h-[103px]" />
                <div className="p-5 gap-y-[20px] gap-x-[30px] flex-wrap bg-white bg-opacity-[0.12] max-h-[470px] min-h-[470px] w-full rounded-[12px] overflow-y-scroll">
                  {reservations
                    .filter(
                      (reservation) =>
                        !selectedUserIdsByRow
                          .filter((_, _row) => _row !== row)
                          .some((selectedUserIds) =>
                            selectedUserIds.includes(reservation.user?.id ?? -1)
                          )
                    )
                    .map((reservation, idx) => (
                      <button
                        className={cn(
                          "flex items-center gap-3",
                          reservation.status === "RESERVED" && "opacity-20"
                        )}
                        onClick={() => {
                          if (reservation.status === "PRESENT") {
                            sendHandleSelectedUserIdsByRow(
                              reservation.user?.id ?? -1,
                              row
                            );
                          }
                        }}
                        key={idx}
                      >
                        <img
                          src={reservation.user?.profileImageUrl ?? "/"}
                          alt="profile"
                          className="w-[36px] h-[36px] rounded-full"
                        />
                        <h2
                          className={cn(
                            "text-[18px] text-white",
                            selectedUserIdsByRow[row]?.includes(
                              reservation.user?.id ?? -1
                            ) && "text-[#53CAF5]"
                          )}
                        >
                          {reservation.user?.name}
                        </h2>
                      </button>
                    ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <BottomNav />
    </LandscapeProgramLayout>
  );
}

export default LandscapeTeamRow;

import { Api } from "../Api";

const api = new Api({
  baseApiParams: {
    headers: {
      get Authorization() {
        return `Bearer ${localStorage.getItem("accessToken")}`;
      },
      get "X-Store-Id"() {
        return localStorage.getItem("storeId") || "";
      },
    },
  },
  baseUrl: process.env.REACT_APP_NEW_API_URL,
});

export default api;

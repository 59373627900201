/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface LoginCreateData {
  accessToken: string;
  refreshToken: string;
  user: {
    id: number;
    phoneNumber: string | null;
    name: string | null;
    role: "USER" | "ADMIN";
    adminType: ("INSTRUCTOR" | "MANAGER" | "HEADQUARTERS") | null;
    profileImageUrl: string | null;
  };
}

export type SignupVerificationCreateData = any;

export type SignupVerifyCreateData = any;

export type SignupCreateData = any;

export type ResetPasswordVerificationCreateData = any;

export type ResetPasswordVerifyCreateData = any;

export type ResetPasswordCreateData = any;

export interface GetUsersData {
  id: number;
  name: string | null;
  createdAt: string;
  phoneNumber: string | null;
  birth: string | null;
  gender: ("MALE" | "FEMALE") | null;
  isNotification: boolean;
  adminType: ("INSTRUCTOR" | "MANAGER" | "HEADQUARTERS") | null;
  address: string | null;
  profileImageUrl: string | null;
  userStores: {
    id: number;
    userId: number;
    storeId: number;
    latestPresentAt: string | null;
    presentCount: number;
    monthlyPresentCount: number;
    createdAt: string;
    deletedAt: string | null;
    store: {
      id: number;
      /** @maxLength 255 */
      name: string;
      tel: string | null;
      phoneNumber: string | null;
      type: "GYM" | "PILATES";
      didAccount: string | null;
      address: string | null;
      addressDetail: string | null;
      point: number;
      timeTable:
        | {
            isDayOff: boolean;
            open?: string;
            close?: string;
          }[]
        | null;
      color: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
  }[];
}

export type PatchUsersData = any;

export type StoreDeleteData = any;

export interface AttendanceListData {
  monthlyAttendanceCount: number;
  continuousAttendanceCount: number;
  monthlyNoshowCount: number;
}

export type InbodyHistoriesListData = {
  id: number;
  userId: number;
  phoneNumber: string | null;
  weight: number | null;
  weightStatus: ("UP" | "DOWN" | "STABLE") | null;
  fat: number | null;
  fatStatus: ("UP" | "DOWN" | "STABLE") | null;
  muscle: number | null;
  muscleStatus: ("UP" | "DOWN" | "STABLE") | null;
  fatPercentage: number | null;
  fatPercentageStatus: ("UP" | "DOWN" | "STABLE") | null;
  createdAt: string;
  updatedAt: string;
}[];

export type RowingRankingListData = {
  id: number;
  classId: number;
  distance: number;
  time: number;
  rank: number;
  createdAt: string;
  updatedAt: string;
  class: {
    id: number;
    /** @maxLength 255 */
    name: string;
    userCount: number;
    maxCapacity: number;
    type: "GROUP" | "PRIVATE";
    reservationAvailableHour: number | null;
    classCancelHour: number;
    sameDayChangeHour: number | null;
    startedAt: string;
    endedAt: string;
    roomId: number | null;
    adminUserId: number | null;
    isReservationEnabled: boolean;
    groupClassId: number | null;
    storeId: number;
    deletedReason: string | null;
    deletedUserId: number | null;
    openedAt: string | null;
    deletedAt: string | null;
    createdAt: string;
    updatedAt: string;
    store: {
      id: number;
      /** @maxLength 255 */
      name: string;
      tel: string | null;
      phoneNumber: string | null;
      type: "GYM" | "PILATES";
      didAccount: string | null;
      address: string | null;
      addressDetail: string | null;
      point: number;
      timeTable:
        | {
            isDayOff: boolean;
            open?: string;
            close?: string;
          }[]
        | null;
      color: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null;
  rowingGroups: {
    id: number;
    userId: number;
    rowingHistoryId: number;
    groupNumber: number;
    createdAt: string;
    updatedAt: string;
    user: {
      id: number;
      isActivated: boolean;
      /** @maxLength 255 */
      username: string;
      name: string | null;
      phoneNumber: string | null;
      refreshToken: string | null;
      role: "USER" | "ADMIN";
      adminType: ("INSTRUCTOR" | "MANAGER" | "HEADQUARTERS") | null;
      password: string | null;
      fcmToken: string | null;
      profileImageUrl: string | null;
      color: string | null;
      description: string | null;
      timeTable:
        | {
            isDayOff: boolean;
            open?: string;
            close?: string;
          }[]
        | null;
      isNotification: boolean;
      isLinked: boolean;
      gender: ("MALE" | "FEMALE") | null;
      birth: string | null;
      address: string | null;
      deletedAt: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
  }[];
}[];

export interface TodayRowingListData {
  id: number;
  classId: number;
  distance: number;
  time: number;
  rank: number;
  createdAt: string;
  updatedAt: string;
}

export type MonthlyPresentRankingListData = {
  id: number;
  userId: number;
  storeId: number;
  latestPresentAt: string | null;
  presentCount: number;
  monthlyPresentCount: number;
  createdAt: string;
  deletedAt: string | null;
  user: {
    id: number;
    isActivated: boolean;
    /** @maxLength 255 */
    username: string;
    name: string | null;
    phoneNumber: string | null;
    refreshToken: string | null;
    role: "USER" | "ADMIN";
    adminType: ("INSTRUCTOR" | "MANAGER" | "HEADQUARTERS") | null;
    password: string | null;
    fcmToken: string | null;
    profileImageUrl: string | null;
    color: string | null;
    description: string | null;
    timeTable:
      | {
          isDayOff: boolean;
          open?: string;
          close?: string;
        }[]
      | null;
    isNotification: boolean;
    isLinked: boolean;
    gender: ("MALE" | "FEMALE") | null;
    birth: string | null;
    address: string | null;
    deletedAt: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
}[];

export type AchievementsListData = {
  id: number;
  userId: number;
  storeId: number | null;
  type: "FIRST_PRESENT" | "WEEK_3" | "WEEK_5" | "MONTH_20" | "50" | "100" | "200" | "300";
  createdAt: string;
}[];

export interface AuthSigninCreateData {
  accessToken: string;
  refreshToken: string;
}

export interface AuthRefreshCreateData {
  accessToken: string;
  refreshToken: string;
}

export interface AuthUserListData {
  username: string;
}

export type AuthSendUsernameCreateData = any;

export type AuthSendResetCreateData = any;

export interface UserMeListData {
  id: number;
  isActivated: boolean;
  /** @maxLength 255 */
  username: string;
  name: string | null;
  phoneNumber: string | null;
  refreshToken: string | null;
  role: "USER" | "ADMIN";
  adminType: ("INSTRUCTOR" | "MANAGER" | "HEADQUARTERS") | null;
  password: string | null;
  fcmToken: string | null;
  profileImageUrl: string | null;
  color: string | null;
  description: string | null;
  timeTable:
    | {
        isDayOff: boolean;
        open?: string;
        close?: string;
      }[]
    | null;
  isNotification: boolean;
  isLinked: boolean;
  gender: ("MALE" | "FEMALE") | null;
  birth: string | null;
  address: string | null;
  deletedAt: string | null;
  createdAt: string;
  updatedAt: string;
  store: null | {
    id: number;
    /** @maxLength 255 */
    name: string;
    tel: string | null;
    phoneNumber: string | null;
    type: "GYM" | "PILATES";
    didAccount: string | null;
    address: string | null;
    addressDetail: string | null;
    point: number;
    timeTable:
      | {
          isDayOff: boolean;
          open?: string;
          close?: string;
        }[]
      | null;
    color: string | null;
    createdAt: string;
    updatedAt: string;
  };
}

export type UserStudentsListData = {
  id: number;
  isActivated: boolean;
  /** @maxLength 255 */
  username: string;
  name: string | null;
  phoneNumber: string | null;
  refreshToken: string | null;
  role: "USER" | "ADMIN";
  adminType: ("INSTRUCTOR" | "MANAGER" | "HEADQUARTERS") | null;
  password: string | null;
  fcmToken: string | null;
  profileImageUrl: string | null;
  color: string | null;
  description: string | null;
  timeTable:
    | {
        isDayOff: boolean;
        open?: string;
        close?: string;
      }[]
    | null;
  isNotification: boolean;
  isLinked: boolean;
  gender: ("MALE" | "FEMALE") | null;
  birth: string | null;
  address: string | null;
  deletedAt: string | null;
  createdAt: string;
  updatedAt: string;
  latestPresentAt: string | null;
  memberships: {
    id: number;
    userId: number;
    planId: number;
    capacity: number;
    count: number;
    cancelableCount: number;
    startedAt: string;
    endedAt: string;
    createdAt: string;
    updatedAt: string;
    plan: {
      id: number;
      /** @maxLength 255 */
      name: string;
      durationType: "COUNT_BASED" | "PERIOD_BASED";
      storeId: number | null;
      classType: "GROUP" | "PRIVATE";
      color: string | null;
      count: number;
      cancelableCount: number;
      durationDays: number | null;
      maxCapacity: number | null;
      liked: boolean;
      createdAt: string;
      updatedAt: string;
    } | null;
    membershipSuspensions: {
      id: number;
      membershipId: number;
      startedAt: string;
      endedAt: string;
      createdAt: string;
      updatedAt: string;
    }[];
    membershipInstructors: {
      id: number;
      membershipId: number;
      userId: number;
      createdAt: string;
      updatedAt: string;
      user: {
        id: number;
        isActivated: boolean;
        /** @maxLength 255 */
        username: string;
        name: string | null;
        phoneNumber: string | null;
        refreshToken: string | null;
        role: "USER" | "ADMIN";
        adminType: ("INSTRUCTOR" | "MANAGER" | "HEADQUARTERS") | null;
        password: string | null;
        fcmToken: string | null;
        profileImageUrl: string | null;
        color: string | null;
        description: string | null;
        timeTable:
          | {
              isDayOff: boolean;
              open?: string;
              close?: string;
            }[]
          | null;
        isNotification: boolean;
        isLinked: boolean;
        gender: ("MALE" | "FEMALE") | null;
        birth: string | null;
        address: string | null;
        deletedAt: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
    }[];
  }[];
}[];

export interface UserCreateData {
  id: number;
}

export type UserPartialUpdateData = any;

export interface UserDetailData {
  id: number;
  isActivated: boolean;
  /** @maxLength 255 */
  username: string;
  name: string | null;
  phoneNumber: string | null;
  refreshToken: string | null;
  role: "USER" | "ADMIN";
  adminType: ("INSTRUCTOR" | "MANAGER" | "HEADQUARTERS") | null;
  password: string | null;
  fcmToken: string | null;
  profileImageUrl: string | null;
  color: string | null;
  description: string | null;
  timeTable:
    | {
        isDayOff: boolean;
        open?: string;
        close?: string;
      }[]
    | null;
  isNotification: boolean;
  isLinked: boolean;
  gender: ("MALE" | "FEMALE") | null;
  birth: string | null;
  address: string | null;
  deletedAt: string | null;
  createdAt: string;
  updatedAt: string;
  latestPresentAt: string | null;
  memberships: {
    id: number;
    userId: number;
    planId: number;
    capacity: number;
    count: number;
    cancelableCount: number;
    startedAt: string;
    endedAt: string;
    createdAt: string;
    updatedAt: string;
    plan: {
      id: number;
      /** @maxLength 255 */
      name: string;
      durationType: "COUNT_BASED" | "PERIOD_BASED";
      storeId: number | null;
      classType: "GROUP" | "PRIVATE";
      color: string | null;
      count: number;
      cancelableCount: number;
      durationDays: number | null;
      maxCapacity: number | null;
      liked: boolean;
      createdAt: string;
      updatedAt: string;
    } | null;
    membershipSuspensions: {
      id: number;
      membershipId: number;
      startedAt: string;
      endedAt: string;
      createdAt: string;
      updatedAt: string;
    }[];
    membershipInstructors: {
      id: number;
      membershipId: number;
      userId: number;
      createdAt: string;
      updatedAt: string;
      user: {
        id: number;
        isActivated: boolean;
        /** @maxLength 255 */
        username: string;
        name: string | null;
        phoneNumber: string | null;
        refreshToken: string | null;
        role: "USER" | "ADMIN";
        adminType: ("INSTRUCTOR" | "MANAGER" | "HEADQUARTERS") | null;
        password: string | null;
        fcmToken: string | null;
        profileImageUrl: string | null;
        color: string | null;
        description: string | null;
        timeTable:
          | {
              isDayOff: boolean;
              open?: string;
              close?: string;
            }[]
          | null;
        isNotification: boolean;
        isLinked: boolean;
        gender: ("MALE" | "FEMALE") | null;
        birth: string | null;
        address: string | null;
        deletedAt: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
    }[];
  }[];
}

export type UserClassesDetailData = {
  id: number;
  /** @maxLength 255 */
  name: string;
  userCount: number;
  maxCapacity: number;
  type: "GROUP" | "PRIVATE";
  reservationAvailableHour: number | null;
  classCancelHour: number;
  sameDayChangeHour: number | null;
  startedAt: string;
  endedAt: string;
  roomId: number | null;
  adminUserId: number | null;
  isReservationEnabled: boolean;
  groupClassId: number | null;
  storeId: number;
  deletedReason: string | null;
  deletedUserId: number | null;
  openedAt: string | null;
  deletedAt: string | null;
  createdAt: string;
  updatedAt: string;
}[];

export type UserStudentsDetailData = {
  id: number;
  isActivated: boolean;
  /** @maxLength 255 */
  username: string;
  name: string | null;
  phoneNumber: string | null;
  refreshToken: string | null;
  role: "USER" | "ADMIN";
  adminType: ("INSTRUCTOR" | "MANAGER" | "HEADQUARTERS") | null;
  password: string | null;
  fcmToken: string | null;
  profileImageUrl: string | null;
  color: string | null;
  description: string | null;
  timeTable:
    | {
        isDayOff: boolean;
        open?: string;
        close?: string;
      }[]
    | null;
  isNotification: boolean;
  isLinked: boolean;
  gender: ("MALE" | "FEMALE") | null;
  birth: string | null;
  address: string | null;
  deletedAt: string | null;
  createdAt: string;
  updatedAt: string;
  latestPresentAt: string | null;
  memberships: {
    id: number;
    userId: number;
    planId: number;
    capacity: number;
    count: number;
    cancelableCount: number;
    startedAt: string;
    endedAt: string;
    createdAt: string;
    updatedAt: string;
    plan: {
      id: number;
      /** @maxLength 255 */
      name: string;
      durationType: "COUNT_BASED" | "PERIOD_BASED";
      storeId: number | null;
      classType: "GROUP" | "PRIVATE";
      color: string | null;
      count: number;
      cancelableCount: number;
      durationDays: number | null;
      maxCapacity: number | null;
      liked: boolean;
      createdAt: string;
      updatedAt: string;
    } | null;
    membershipSuspensions: {
      id: number;
      membershipId: number;
      startedAt: string;
      endedAt: string;
      createdAt: string;
      updatedAt: string;
    }[];
    membershipInstructors: {
      id: number;
      membershipId: number;
      userId: number;
      createdAt: string;
      updatedAt: string;
      user: {
        id: number;
        isActivated: boolean;
        /** @maxLength 255 */
        username: string;
        name: string | null;
        phoneNumber: string | null;
        refreshToken: string | null;
        role: "USER" | "ADMIN";
        adminType: ("INSTRUCTOR" | "MANAGER" | "HEADQUARTERS") | null;
        password: string | null;
        fcmToken: string | null;
        profileImageUrl: string | null;
        color: string | null;
        description: string | null;
        timeTable:
          | {
              isDayOff: boolean;
              open?: string;
              close?: string;
            }[]
          | null;
        isNotification: boolean;
        isLinked: boolean;
        gender: ("MALE" | "FEMALE") | null;
        birth: string | null;
        address: string | null;
        deletedAt: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
    }[];
  }[];
}[];

export interface UserReservationCountDetailData {
  reservedCount: number;
  canceledCount: number;
  presentCount: number;
  noshowCount: number;
}

export type UserInbodyHistoriesListData = {
  id: number;
  userId: number;
  phoneNumber: string | null;
  weight: number | null;
  weightStatus: ("UP" | "DOWN" | "STABLE") | null;
  fat: number | null;
  fatStatus: ("UP" | "DOWN" | "STABLE") | null;
  muscle: number | null;
  muscleStatus: ("UP" | "DOWN" | "STABLE") | null;
  fatPercentage: number | null;
  fatPercentageStatus: ("UP" | "DOWN" | "STABLE") | null;
  createdAt: string;
  updatedAt: string;
  user: {
    id: number;
    isActivated: boolean;
    /** @maxLength 255 */
    username: string;
    name: string | null;
    phoneNumber: string | null;
    refreshToken: string | null;
    role: "USER" | "ADMIN";
    adminType: ("INSTRUCTOR" | "MANAGER" | "HEADQUARTERS") | null;
    password: string | null;
    fcmToken: string | null;
    profileImageUrl: string | null;
    color: string | null;
    description: string | null;
    timeTable:
      | {
          isDayOff: boolean;
          open?: string;
          close?: string;
        }[]
      | null;
    isNotification: boolean;
    isLinked: boolean;
    gender: ("MALE" | "FEMALE") | null;
    birth: string | null;
    address: string | null;
    deletedAt: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
}[];

export type UserInbodyHistoriesDetailData = {
  id: number;
  userId: number;
  phoneNumber: string | null;
  weight: number | null;
  weightStatus: ("UP" | "DOWN" | "STABLE") | null;
  fat: number | null;
  fatStatus: ("UP" | "DOWN" | "STABLE") | null;
  muscle: number | null;
  muscleStatus: ("UP" | "DOWN" | "STABLE") | null;
  fatPercentage: number | null;
  fatPercentageStatus: ("UP" | "DOWN" | "STABLE") | null;
  createdAt: string;
  updatedAt: string;
  user: {
    id: number;
    isActivated: boolean;
    /** @maxLength 255 */
    username: string;
    name: string | null;
    phoneNumber: string | null;
    refreshToken: string | null;
    role: "USER" | "ADMIN";
    adminType: ("INSTRUCTOR" | "MANAGER" | "HEADQUARTERS") | null;
    password: string | null;
    fcmToken: string | null;
    profileImageUrl: string | null;
    color: string | null;
    description: string | null;
    timeTable:
      | {
          isDayOff: boolean;
          open?: string;
          close?: string;
        }[]
      | null;
    isNotification: boolean;
    isLinked: boolean;
    gender: ("MALE" | "FEMALE") | null;
    birth: string | null;
    address: string | null;
    deletedAt: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
}[];

export type UserMembershipHistoriesListData = {
  id: number;
  adminUserId: number;
  membershipId: number;
  action: "UPDATE" | "EXTEND" | "SUSPENSION";
  before: {
    capacity?: number;
    count?: number;
    startedAt?: string;
    endedAt?: string;
    suspensionStartedAt?: string;
    suspensionEndedAt?: string;
    membershipInstructorsNames?: string[];
  } | null;
  after: {
    capacity?: number;
    count?: number;
    startedAt?: string;
    endedAt?: string;
    suspensionStartedAt?: string;
    suspensionEndedAt?: string;
    membershipInstructorsNames?: string[];
  } | null;
  description: string | null;
  createdAt: string;
  adminUser: {
    id: number;
    isActivated: boolean;
    /** @maxLength 255 */
    username: string;
    name: string | null;
    phoneNumber: string | null;
    refreshToken: string | null;
    role: "USER" | "ADMIN";
    adminType: ("INSTRUCTOR" | "MANAGER" | "HEADQUARTERS") | null;
    password: string | null;
    fcmToken: string | null;
    profileImageUrl: string | null;
    color: string | null;
    description: string | null;
    timeTable:
      | {
          isDayOff: boolean;
          open?: string;
          close?: string;
        }[]
      | null;
    isNotification: boolean;
    isLinked: boolean;
    gender: ("MALE" | "FEMALE") | null;
    birth: string | null;
    address: string | null;
    deletedAt: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  membership: {
    id: number;
    userId: number;
    planId: number;
    capacity: number;
    count: number;
    cancelableCount: number;
    startedAt: string;
    endedAt: string;
    createdAt: string;
    updatedAt: string;
    plan: {
      id: number;
      /** @maxLength 255 */
      name: string;
      durationType: "COUNT_BASED" | "PERIOD_BASED";
      storeId: number | null;
      classType: "GROUP" | "PRIVATE";
      color: string | null;
      count: number;
      cancelableCount: number;
      durationDays: number | null;
      maxCapacity: number | null;
      liked: boolean;
      createdAt: string;
      updatedAt: string;
    } | null;
    user: {
      id: number;
      isActivated: boolean;
      /** @maxLength 255 */
      username: string;
      name: string | null;
      phoneNumber: string | null;
      refreshToken: string | null;
      role: "USER" | "ADMIN";
      adminType: ("INSTRUCTOR" | "MANAGER" | "HEADQUARTERS") | null;
      password: string | null;
      fcmToken: string | null;
      profileImageUrl: string | null;
      color: string | null;
      description: string | null;
      timeTable:
        | {
            isDayOff: boolean;
            open?: string;
            close?: string;
          }[]
        | null;
      isNotification: boolean;
      isLinked: boolean;
      gender: ("MALE" | "FEMALE") | null;
      birth: string | null;
      address: string | null;
      deletedAt: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null;
}[];

export type UserMembershipHistoriesDetailData = {
  id: number;
  adminUserId: number;
  membershipId: number;
  action: "UPDATE" | "EXTEND" | "SUSPENSION";
  before: {
    capacity?: number;
    count?: number;
    startedAt?: string;
    endedAt?: string;
    suspensionStartedAt?: string;
    suspensionEndedAt?: string;
    membershipInstructorsNames?: string[];
  } | null;
  after: {
    capacity?: number;
    count?: number;
    startedAt?: string;
    endedAt?: string;
    suspensionStartedAt?: string;
    suspensionEndedAt?: string;
    membershipInstructorsNames?: string[];
  } | null;
  description: string | null;
  createdAt: string;
  adminUser: {
    id: number;
    isActivated: boolean;
    /** @maxLength 255 */
    username: string;
    name: string | null;
    phoneNumber: string | null;
    refreshToken: string | null;
    role: "USER" | "ADMIN";
    adminType: ("INSTRUCTOR" | "MANAGER" | "HEADQUARTERS") | null;
    password: string | null;
    fcmToken: string | null;
    profileImageUrl: string | null;
    color: string | null;
    description: string | null;
    timeTable:
      | {
          isDayOff: boolean;
          open?: string;
          close?: string;
        }[]
      | null;
    isNotification: boolean;
    isLinked: boolean;
    gender: ("MALE" | "FEMALE") | null;
    birth: string | null;
    address: string | null;
    deletedAt: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  membership: {
    id: number;
    userId: number;
    planId: number;
    capacity: number;
    count: number;
    cancelableCount: number;
    startedAt: string;
    endedAt: string;
    createdAt: string;
    updatedAt: string;
    plan: {
      id: number;
      /** @maxLength 255 */
      name: string;
      durationType: "COUNT_BASED" | "PERIOD_BASED";
      storeId: number | null;
      classType: "GROUP" | "PRIVATE";
      color: string | null;
      count: number;
      cancelableCount: number;
      durationDays: number | null;
      maxCapacity: number | null;
      liked: boolean;
      createdAt: string;
      updatedAt: string;
    } | null;
    user: {
      id: number;
      isActivated: boolean;
      /** @maxLength 255 */
      username: string;
      name: string | null;
      phoneNumber: string | null;
      refreshToken: string | null;
      role: "USER" | "ADMIN";
      adminType: ("INSTRUCTOR" | "MANAGER" | "HEADQUARTERS") | null;
      password: string | null;
      fcmToken: string | null;
      profileImageUrl: string | null;
      color: string | null;
      description: string | null;
      timeTable:
        | {
            isDayOff: boolean;
            open?: string;
            close?: string;
          }[]
        | null;
      isNotification: boolean;
      isLinked: boolean;
      gender: ("MALE" | "FEMALE") | null;
      birth: string | null;
      address: string | null;
      deletedAt: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null;
}[];

export type UserMemosDetailData = {
  id: number;
  userId: number;
  adminUserId: number;
  content: string | null;
  createdAt: string;
  updatedAt: string;
  adminUser: {
    id: number;
    isActivated: boolean;
    /** @maxLength 255 */
    username: string;
    name: string | null;
    phoneNumber: string | null;
    refreshToken: string | null;
    role: "USER" | "ADMIN";
    adminType: ("INSTRUCTOR" | "MANAGER" | "HEADQUARTERS") | null;
    password: string | null;
    fcmToken: string | null;
    profileImageUrl: string | null;
    color: string | null;
    description: string | null;
    timeTable:
      | {
          isDayOff: boolean;
          open?: string;
          close?: string;
        }[]
      | null;
    isNotification: boolean;
    isLinked: boolean;
    gender: ("MALE" | "FEMALE") | null;
    birth: string | null;
    address: string | null;
    deletedAt: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
}[];

export type UserMemosCreateData = any;

export type UserMemosDeleteData = any;

export type UserMemosPartialUpdateData = any;

export type UserMembershipsDetailData = {
  id: number;
  userId: number;
  planId: number;
  capacity: number;
  count: number;
  cancelableCount: number;
  startedAt: string;
  endedAt: string;
  createdAt: string;
  updatedAt: string;
}[];

export type UserMembershipsCreateData = any;

export type UserMembershipsPartialUpdateData = any;

export type UserExtendCreateData = any;

export type UserSuspendCreateData = any;

export type UserInstructorsListData = {
  id: number;
  isActivated: boolean;
  /** @maxLength 255 */
  username: string;
  name: string | null;
  phoneNumber: string | null;
  refreshToken: string | null;
  role: "USER" | "ADMIN";
  adminType: ("INSTRUCTOR" | "MANAGER" | "HEADQUARTERS") | null;
  password: string | null;
  fcmToken: string | null;
  profileImageUrl: string | null;
  color: string | null;
  description: string | null;
  timeTable:
    | {
        isDayOff: boolean;
        open?: string;
        close?: string;
      }[]
    | null;
  isNotification: boolean;
  isLinked: boolean;
  gender: ("MALE" | "FEMALE") | null;
  birth: string | null;
  address: string | null;
  deletedAt: string | null;
  createdAt: string;
  updatedAt: string;
}[];

export type StoreListData = {
  id: number;
  /** @maxLength 255 */
  name: string;
  tel: string | null;
  phoneNumber: string | null;
  type: "GYM" | "PILATES";
  didAccount: string | null;
  address: string | null;
  addressDetail: string | null;
  point: number;
  timeTable:
    | {
        isDayOff: boolean;
        open?: string;
        close?: string;
      }[]
    | null;
  color: string | null;
  createdAt: string;
  updatedAt: string;
  username: string;
  adminName: string;
}[];

export interface StoreCreateData {
  id: number;
  /** @maxLength 255 */
  name: string;
  tel: string | null;
  phoneNumber: string | null;
  type: "GYM" | "PILATES";
  didAccount: string | null;
  address: string | null;
  addressDetail: string | null;
  point: number;
  timeTable:
    | {
        isDayOff: boolean;
        open?: string;
        close?: string;
      }[]
    | null;
  color: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface StoreMyListData {
  id: number;
  /** @maxLength 255 */
  name: string;
  tel: string | null;
  phoneNumber: string | null;
  type: "GYM" | "PILATES";
  didAccount: string | null;
  address: string | null;
  addressDetail: string | null;
  point: number;
  timeTable:
    | {
        isDayOff: boolean;
        open?: string;
        close?: string;
      }[]
    | null;
  color: string | null;
  createdAt: string;
  updatedAt: string;
}

export type StoreStoreDeleteData = any;

export interface StoreSettingsListData {
  id: number;
  storeId: number;
  reservationAvailableHour: number;
  groupClassCancelHour: number;
  privateClassCancelHour: number;
  sameDayChangeHour: number;
  waitingAutoReserveHour: number;
  dailyReservationLimit: number;
  checkInScreenImage: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface StoreSettingsCreateData {
  id: number;
  storeId: number;
  reservationAvailableHour: number;
  groupClassCancelHour: number;
  privateClassCancelHour: number;
  sameDayChangeHour: number;
  waitingAutoReserveHour: number;
  dailyReservationLimit: number;
  checkInScreenImage: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface StorePermissionsListData {
  id: number;
  storeId: number;
  instructorGroupClassModifyYn: boolean;
  instructorPrivateClassCreateYn: boolean;
  instructorPrivateClassModifyYn: boolean;
  instructorPrivateClassReserveModifyYn: boolean;
  instructorPrivateClassDeleteYn: boolean;
  otherInstructorGroupClassViewYn: boolean;
  otherInstructorPrivateClassViewYn: boolean;
  memberListViewYn: boolean;
  memberCreateYn: boolean;
  memberTicketModifyYn: boolean;
  instructorListViewYn: boolean;
  ticketCreateYn: boolean;
  ticketModifyYn: boolean;
  operationSettingYn: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface StoreStorePermissionsCreateData {
  id: number;
  storeId: number;
  instructorGroupClassModifyYn: boolean;
  instructorPrivateClassCreateYn: boolean;
  instructorPrivateClassModifyYn: boolean;
  instructorPrivateClassReserveModifyYn: boolean;
  instructorPrivateClassDeleteYn: boolean;
  otherInstructorGroupClassViewYn: boolean;
  otherInstructorPrivateClassViewYn: boolean;
  memberListViewYn: boolean;
  memberCreateYn: boolean;
  memberTicketModifyYn: boolean;
  instructorListViewYn: boolean;
  ticketCreateYn: boolean;
  ticketModifyYn: boolean;
  operationSettingYn: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface StoreNotificationsListData {
  id: number;
  storeId: number;
  ticketExpireDays: number;
  ticketExpireTemplate: string;
  ticketExpireNotificationType: "PUSH" | "SMS";
  ticketRemainCount: number;
  ticketRemainTemplate: string;
  ticketRemainNotificationType: "PUSH" | "SMS";
  pausePeriodExpireDays: number;
  pausePeriodExpireTemplate: string;
  waitingToReserveTemplate: string;
  waitingToReserveNotificationType: "PUSH" | "SMS";
  groupClassStartHour: number;
  groupClassStartTemplate: string;
  groupClassStartNotificationType: "PUSH" | "SMS";
  privateClassStartHour: number;
  privateClassStartTemplate: string;
  privateClassStartNotificationType: "PUSH" | "SMS";
  birthdayTemplate: string;
  birthdayNotificationType: "PUSH" | "SMS";
  createdAt: string;
  updatedAt: string;
}

export interface StoreNotificationsCreateData {
  id: number;
  storeId: number;
  ticketExpireDays: number;
  ticketExpireTemplate: string;
  ticketExpireNotificationType: "PUSH" | "SMS";
  ticketRemainCount: number;
  ticketRemainTemplate: string;
  ticketRemainNotificationType: "PUSH" | "SMS";
  pausePeriodExpireDays: number;
  pausePeriodExpireTemplate: string;
  waitingToReserveTemplate: string;
  waitingToReserveNotificationType: "PUSH" | "SMS";
  groupClassStartHour: number;
  groupClassStartTemplate: string;
  groupClassStartNotificationType: "PUSH" | "SMS";
  privateClassStartHour: number;
  privateClassStartTemplate: string;
  privateClassStartNotificationType: "PUSH" | "SMS";
  birthdayTemplate: string;
  birthdayNotificationType: "PUSH" | "SMS";
  createdAt: string;
  updatedAt: string;
}

export type StoreRoomsListData = {
  id: number;
  /** @maxLength 255 */
  name: string;
  storeId: number | null;
  createdAt: string;
  updatedAt: string;
}[];

export type StoreRoomsCreateData = {
  id: number;
  /** @maxLength 255 */
  name: string;
  storeId: number | null;
  createdAt: string;
  updatedAt: string;
}[];

export interface MembershipListData {
  id: number;
  userId: number;
  planId: number;
  capacity: number;
  count: number;
  cancelableCount: number;
  startedAt: string;
  endedAt: string;
  createdAt: string;
  updatedAt: string;
}

export type MembershipCreateData = any;

export type MembershipPlanListData = {
  id: number;
  /** @maxLength 255 */
  name: string;
  durationType: "COUNT_BASED" | "PERIOD_BASED";
  storeId: number | null;
  classType: "GROUP" | "PRIVATE";
  color: string | null;
  count: number;
  cancelableCount: number;
  durationDays: number | null;
  maxCapacity: number | null;
  liked: boolean;
  createdAt: string;
  updatedAt: string;
}[];

export type MembershipPlanCreateData = any;

export interface MembershipPlanDetailData {
  id: number;
  /** @maxLength 255 */
  name: string;
  durationType: "COUNT_BASED" | "PERIOD_BASED";
  storeId: number | null;
  classType: "GROUP" | "PRIVATE";
  color: string | null;
  count: number;
  cancelableCount: number;
  durationDays: number | null;
  maxCapacity: number | null;
  liked: boolean;
  createdAt: string;
  updatedAt: string;
}

export type MembershipPlanMembershipsDetailData = {
  id: number;
  userId: number;
  planId: number;
  capacity: number;
  count: number;
  cancelableCount: number;
  startedAt: string;
  endedAt: string;
  createdAt: string;
  updatedAt: string;
  user: {
    id: number;
    isActivated: boolean;
    /** @maxLength 255 */
    username: string;
    name: string | null;
    phoneNumber: string | null;
    refreshToken: string | null;
    role: "USER" | "ADMIN";
    adminType: ("INSTRUCTOR" | "MANAGER" | "HEADQUARTERS") | null;
    password: string | null;
    fcmToken: string | null;
    profileImageUrl: string | null;
    color: string | null;
    description: string | null;
    timeTable:
      | {
          isDayOff: boolean;
          open?: string;
          close?: string;
        }[]
      | null;
    isNotification: boolean;
    isLinked: boolean;
    gender: ("MALE" | "FEMALE") | null;
    birth: string | null;
    address: string | null;
    deletedAt: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  membershipSuspensions: {
    id: number;
    membershipId: number;
    startedAt: string;
    endedAt: string;
    createdAt: string;
    updatedAt: string;
  }[];
}[];

export type ClassListData = {
  id: number;
  /** @maxLength 255 */
  name: string;
  userCount: number;
  maxCapacity: number;
  type: "GROUP" | "PRIVATE";
  reservationAvailableHour: number | null;
  classCancelHour: number;
  sameDayChangeHour: number | null;
  startedAt: string;
  endedAt: string;
  roomId: number | null;
  adminUserId: number | null;
  isReservationEnabled: boolean;
  groupClassId: number | null;
  storeId: number;
  deletedReason: string | null;
  deletedUserId: number | null;
  openedAt: string | null;
  deletedAt: string | null;
  createdAt: string;
  updatedAt: string;
  adminUser: {
    id: number;
    isActivated: boolean;
    /** @maxLength 255 */
    username: string;
    name: string | null;
    phoneNumber: string | null;
    refreshToken: string | null;
    role: "USER" | "ADMIN";
    adminType: ("INSTRUCTOR" | "MANAGER" | "HEADQUARTERS") | null;
    password: string | null;
    fcmToken: string | null;
    profileImageUrl: string | null;
    color: string | null;
    description: string | null;
    timeTable:
      | {
          isDayOff: boolean;
          open?: string;
          close?: string;
        }[]
      | null;
    isNotification: boolean;
    isLinked: boolean;
    gender: ("MALE" | "FEMALE") | null;
    birth: string | null;
    address: string | null;
    deletedAt: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  room: {
    id: number;
    /** @maxLength 255 */
    name: string;
    storeId: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  reservations: {
    id: number;
    userId: number;
    classId: number;
    status: "RESERVED" | "CANCELED" | "PRESENT" | "NO_SHOW" | "WAITING";
    membershipId: number;
    canceledAt: string | null;
    createdAt: string;
    updatedAt: string;
    user: {
      id: number;
      isActivated: boolean;
      /** @maxLength 255 */
      username: string;
      name: string | null;
      phoneNumber: string | null;
      refreshToken: string | null;
      role: "USER" | "ADMIN";
      adminType: ("INSTRUCTOR" | "MANAGER" | "HEADQUARTERS") | null;
      password: string | null;
      fcmToken: string | null;
      profileImageUrl: string | null;
      color: string | null;
      description: string | null;
      timeTable:
        | {
            isDayOff: boolean;
            open?: string;
            close?: string;
          }[]
        | null;
      isNotification: boolean;
      isLinked: boolean;
      gender: ("MALE" | "FEMALE") | null;
      birth: string | null;
      address: string | null;
      deletedAt: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    membership: {
      id: number;
      userId: number;
      planId: number;
      capacity: number;
      count: number;
      cancelableCount: number;
      startedAt: string;
      endedAt: string;
      createdAt: string;
      updatedAt: string;
      plan: {
        id: number;
        /** @maxLength 255 */
        name: string;
        durationType: "COUNT_BASED" | "PERIOD_BASED";
        storeId: number | null;
        classType: "GROUP" | "PRIVATE";
        color: string | null;
        count: number;
        cancelableCount: number;
        durationDays: number | null;
        maxCapacity: number | null;
        liked: boolean;
        createdAt: string;
        updatedAt: string;
      };
    } | null;
  }[];
}[];

export type ClassBulkOpenCreateData = any;

export type ClassBulkDeleteDeleteData = any;

export type ClassBulkUpdateCreateData = any;

export type ClassReservationsListData = {
  id: number;
  /** @maxLength 255 */
  name: string;
  userCount: number;
  maxCapacity: number;
  type: "GROUP" | "PRIVATE";
  reservationAvailableHour: number | null;
  classCancelHour: number;
  sameDayChangeHour: number | null;
  startedAt: string;
  endedAt: string;
  roomId: number | null;
  adminUserId: number | null;
  isReservationEnabled: boolean;
  groupClassId: number | null;
  storeId: number;
  deletedReason: string | null;
  deletedUserId: number | null;
  openedAt: string | null;
  deletedAt: string | null;
  createdAt: string;
  updatedAt: string;
  adminUser: {
    id: number;
    isActivated: boolean;
    /** @maxLength 255 */
    username: string;
    name: string | null;
    phoneNumber: string | null;
    refreshToken: string | null;
    role: "USER" | "ADMIN";
    adminType: ("INSTRUCTOR" | "MANAGER" | "HEADQUARTERS") | null;
    password: string | null;
    fcmToken: string | null;
    profileImageUrl: string | null;
    color: string | null;
    description: string | null;
    timeTable:
      | {
          isDayOff: boolean;
          open?: string;
          close?: string;
        }[]
      | null;
    isNotification: boolean;
    isLinked: boolean;
    gender: ("MALE" | "FEMALE") | null;
    birth: string | null;
    address: string | null;
    deletedAt: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  room: {
    id: number;
    /** @maxLength 255 */
    name: string;
    storeId: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  deletedUser: {
    id: number;
    isActivated: boolean;
    /** @maxLength 255 */
    username: string;
    name: string | null;
    phoneNumber: string | null;
    refreshToken: string | null;
    role: "USER" | "ADMIN";
    adminType: ("INSTRUCTOR" | "MANAGER" | "HEADQUARTERS") | null;
    password: string | null;
    fcmToken: string | null;
    profileImageUrl: string | null;
    color: string | null;
    description: string | null;
    timeTable:
      | {
          isDayOff: boolean;
          open?: string;
          close?: string;
        }[]
      | null;
    isNotification: boolean;
    isLinked: boolean;
    gender: ("MALE" | "FEMALE") | null;
    birth: string | null;
    address: string | null;
    deletedAt: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  reservations: {
    id: number;
    userId: number;
    classId: number;
    status: "RESERVED" | "CANCELED" | "PRESENT" | "NO_SHOW" | "WAITING";
    membershipId: number;
    canceledAt: string | null;
    createdAt: string;
    updatedAt: string;
    user: {
      id: number;
      isActivated: boolean;
      /** @maxLength 255 */
      username: string;
      name: string | null;
      phoneNumber: string | null;
      refreshToken: string | null;
      role: "USER" | "ADMIN";
      adminType: ("INSTRUCTOR" | "MANAGER" | "HEADQUARTERS") | null;
      password: string | null;
      fcmToken: string | null;
      profileImageUrl: string | null;
      color: string | null;
      description: string | null;
      timeTable:
        | {
            isDayOff: boolean;
            open?: string;
            close?: string;
          }[]
        | null;
      isNotification: boolean;
      isLinked: boolean;
      gender: ("MALE" | "FEMALE") | null;
      birth: string | null;
      address: string | null;
      deletedAt: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    membership: {
      id: number;
      userId: number;
      planId: number;
      capacity: number;
      count: number;
      cancelableCount: number;
      startedAt: string;
      endedAt: string;
      createdAt: string;
      updatedAt: string;
      plan: {
        id: number;
        /** @maxLength 255 */
        name: string;
        durationType: "COUNT_BASED" | "PERIOD_BASED";
        storeId: number | null;
        classType: "GROUP" | "PRIVATE";
        color: string | null;
        count: number;
        cancelableCount: number;
        durationDays: number | null;
        maxCapacity: number | null;
        liked: boolean;
        createdAt: string;
        updatedAt: string;
      };
    } | null;
  }[];
}[];

export type ClassDeletedListData = {
  id: number;
  /** @maxLength 255 */
  name: string;
  userCount: number;
  maxCapacity: number;
  type: "GROUP" | "PRIVATE";
  reservationAvailableHour: number | null;
  classCancelHour: number;
  sameDayChangeHour: number | null;
  startedAt: string;
  endedAt: string;
  roomId: number | null;
  adminUserId: number | null;
  isReservationEnabled: boolean;
  groupClassId: number | null;
  storeId: number;
  deletedReason: string | null;
  deletedUserId: number | null;
  openedAt: string | null;
  deletedAt: string | null;
  createdAt: string;
  updatedAt: string;
  adminUser: {
    id: number;
    isActivated: boolean;
    /** @maxLength 255 */
    username: string;
    name: string | null;
    phoneNumber: string | null;
    refreshToken: string | null;
    role: "USER" | "ADMIN";
    adminType: ("INSTRUCTOR" | "MANAGER" | "HEADQUARTERS") | null;
    password: string | null;
    fcmToken: string | null;
    profileImageUrl: string | null;
    color: string | null;
    description: string | null;
    timeTable:
      | {
          isDayOff: boolean;
          open?: string;
          close?: string;
        }[]
      | null;
    isNotification: boolean;
    isLinked: boolean;
    gender: ("MALE" | "FEMALE") | null;
    birth: string | null;
    address: string | null;
    deletedAt: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  room: {
    id: number;
    /** @maxLength 255 */
    name: string;
    storeId: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  deletedUser: {
    id: number;
    isActivated: boolean;
    /** @maxLength 255 */
    username: string;
    name: string | null;
    phoneNumber: string | null;
    refreshToken: string | null;
    role: "USER" | "ADMIN";
    adminType: ("INSTRUCTOR" | "MANAGER" | "HEADQUARTERS") | null;
    password: string | null;
    fcmToken: string | null;
    profileImageUrl: string | null;
    color: string | null;
    description: string | null;
    timeTable:
      | {
          isDayOff: boolean;
          open?: string;
          close?: string;
        }[]
      | null;
    isNotification: boolean;
    isLinked: boolean;
    gender: ("MALE" | "FEMALE") | null;
    birth: string | null;
    address: string | null;
    deletedAt: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  reservations: {
    id: number;
    userId: number;
    classId: number;
    status: "RESERVED" | "CANCELED" | "PRESENT" | "NO_SHOW" | "WAITING";
    membershipId: number;
    canceledAt: string | null;
    createdAt: string;
    updatedAt: string;
    user: {
      id: number;
      isActivated: boolean;
      /** @maxLength 255 */
      username: string;
      name: string | null;
      phoneNumber: string | null;
      refreshToken: string | null;
      role: "USER" | "ADMIN";
      adminType: ("INSTRUCTOR" | "MANAGER" | "HEADQUARTERS") | null;
      password: string | null;
      fcmToken: string | null;
      profileImageUrl: string | null;
      color: string | null;
      description: string | null;
      timeTable:
        | {
            isDayOff: boolean;
            open?: string;
            close?: string;
          }[]
        | null;
      isNotification: boolean;
      isLinked: boolean;
      gender: ("MALE" | "FEMALE") | null;
      birth: string | null;
      address: string | null;
      deletedAt: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    membership: {
      id: number;
      userId: number;
      planId: number;
      capacity: number;
      count: number;
      cancelableCount: number;
      startedAt: string;
      endedAt: string;
      createdAt: string;
      updatedAt: string;
      plan: {
        id: number;
        /** @maxLength 255 */
        name: string;
        durationType: "COUNT_BASED" | "PERIOD_BASED";
        storeId: number | null;
        classType: "GROUP" | "PRIVATE";
        color: string | null;
        count: number;
        cancelableCount: number;
        durationDays: number | null;
        maxCapacity: number | null;
        liked: boolean;
        createdAt: string;
        updatedAt: string;
      };
    } | null;
  }[];
}[];

export interface ClassDetailData {
  id: number;
  /** @maxLength 255 */
  name: string;
  userCount: number;
  maxCapacity: number;
  type: "GROUP" | "PRIVATE";
  reservationAvailableHour: number | null;
  classCancelHour: number;
  sameDayChangeHour: number | null;
  startedAt: string;
  endedAt: string;
  roomId: number | null;
  adminUserId: number | null;
  isReservationEnabled: boolean;
  groupClassId: number | null;
  storeId: number;
  deletedReason: string | null;
  deletedUserId: number | null;
  openedAt: string | null;
  deletedAt: string | null;
  createdAt: string;
  updatedAt: string;
  adminUser: {
    id: number;
    isActivated: boolean;
    /** @maxLength 255 */
    username: string;
    name: string | null;
    phoneNumber: string | null;
    refreshToken: string | null;
    role: "USER" | "ADMIN";
    adminType: ("INSTRUCTOR" | "MANAGER" | "HEADQUARTERS") | null;
    password: string | null;
    fcmToken: string | null;
    profileImageUrl: string | null;
    color: string | null;
    description: string | null;
    timeTable:
      | {
          isDayOff: boolean;
          open?: string;
          close?: string;
        }[]
      | null;
    isNotification: boolean;
    isLinked: boolean;
    gender: ("MALE" | "FEMALE") | null;
    birth: string | null;
    address: string | null;
    deletedAt: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  room: {
    id: number;
    /** @maxLength 255 */
    name: string;
    storeId: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  classAvailablePlans: {
    id: number;
    classId: number;
    planId: number;
    createdAt: string;
    updatedAt: string;
  }[];
  reservations: {
    id: number;
    userId: number;
    classId: number;
    status: "RESERVED" | "CANCELED" | "PRESENT" | "NO_SHOW" | "WAITING";
    membershipId: number;
    canceledAt: string | null;
    createdAt: string;
    updatedAt: string;
    user: {
      id: number;
      isActivated: boolean;
      /** @maxLength 255 */
      username: string;
      name: string | null;
      phoneNumber: string | null;
      refreshToken: string | null;
      role: "USER" | "ADMIN";
      adminType: ("INSTRUCTOR" | "MANAGER" | "HEADQUARTERS") | null;
      password: string | null;
      fcmToken: string | null;
      profileImageUrl: string | null;
      color: string | null;
      description: string | null;
      timeTable:
        | {
            isDayOff: boolean;
            open?: string;
            close?: string;
          }[]
        | null;
      isNotification: boolean;
      isLinked: boolean;
      gender: ("MALE" | "FEMALE") | null;
      birth: string | null;
      address: string | null;
      deletedAt: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    membership: {
      id: number;
      userId: number;
      planId: number;
      capacity: number;
      count: number;
      cancelableCount: number;
      startedAt: string;
      endedAt: string;
      createdAt: string;
      updatedAt: string;
      plan: {
        id: number;
        /** @maxLength 255 */
        name: string;
        durationType: "COUNT_BASED" | "PERIOD_BASED";
        storeId: number | null;
        classType: "GROUP" | "PRIVATE";
        color: string | null;
        count: number;
        cancelableCount: number;
        durationDays: number | null;
        maxCapacity: number | null;
        liked: boolean;
        createdAt: string;
        updatedAt: string;
      };
    } | null;
  }[];
  groupClass: {
    id: number;
    /** @maxLength 255 */
    name: string;
    maxCapacity: number;
    reservationAvailableHour: number | null;
    classCancelHour: number;
    sameDayChangeHour: number | null;
    startedAt: string;
    endedAt: string;
    timeTable: {
      day: number;
      start: string;
      end: string;
      adminUserId: number;
      roomId: number | null;
    }[];
    storeId: number;
    openedAt: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
}

export type ClassDeleteData = any;

export type ClassPartialUpdateData = any;

export type ClassPrivateCreateData = any;

export type ClassMemosDetailData = {
  id: number;
  classId: number;
  adminUserId: number;
  content: string | null;
  createdAt: string;
  updatedAt: string;
  adminUser: {
    id: number;
    isActivated: boolean;
    /** @maxLength 255 */
    username: string;
    name: string | null;
    phoneNumber: string | null;
    refreshToken: string | null;
    role: "USER" | "ADMIN";
    adminType: ("INSTRUCTOR" | "MANAGER" | "HEADQUARTERS") | null;
    password: string | null;
    fcmToken: string | null;
    profileImageUrl: string | null;
    color: string | null;
    description: string | null;
    timeTable:
      | {
          isDayOff: boolean;
          open?: string;
          close?: string;
        }[]
      | null;
    isNotification: boolean;
    isLinked: boolean;
    gender: ("MALE" | "FEMALE") | null;
    birth: string | null;
    address: string | null;
    deletedAt: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
}[];

export type ClassMemosCreateData = any;

export type ClassMemosDeleteData = any;

export type ClassMemosPartialUpdateData = any;

export type GroupClassCreateData = any;

export type GroupClassPartialUpdateData = any;

export interface PaymentAuthCallbackCreateData {
  id: number;
}

export type PaymentAuthCallbackCreateError = string;

export type MessagesPurchaseCreateData = string;

export type MessagesPurchaseBillingCreateData = any;

export type MessagesListData = {
  id: number;
  senderId: number;
  storeId: number | null;
  title: string | null;
  message: string;
  scheduleAt: string | null;
  imageUrls: string[] | null;
  usingPoint: number;
  createdAt: string;
  updatedAt: string;
}[];

export type MessagesCreateData = any;

export type MessagesPointPaymentHistoriesListData = {
  id: number;
  userId: number;
  storeId: number | null;
  addedPoint: number;
  afterPoint: number;
  amount: number;
  tid: string | null;
  paymentMethod: "CARD" | "PHONE" | "KAKAO" | "BILLING" | "NAVER";
  approvalCode: string | null;
  createdAt: string;
}[];

export type BillingsRegisterCreateData = string;

export type BillingsUnauthRegisterCreateData = any;

export type BillingsAuthCallbackCreateError = string;

export type BillingsDeleteData = any;

export type BillingsListData = {
  id: number;
  storeId: number;
  cardName: string | null;
  cardCi: string | null;
  createdAt: string;
  updatedAt: string;
}[];

export type ReservationsPartialUpdateData = any;

export type ReservationsDeleteData = any;

export interface ReservationsUserReservationsListData {
  reservations: {
    id: number;
    startedAt: string;
    endedAt: string;
    roomName: string;
    instructor: string;
    name: string;
  }[];
  userName: string;
}

export type ReservationsPresentCreateData = any;

export type GetMembershipsData = {
  id: number;
  userId: number;
  planId: number;
  capacity: number;
  count: number;
  cancelableCount: number;
  startedAt: string;
  endedAt: string;
  createdAt: string;
  updatedAt: string;
  plan: {
    id: number;
    /** @maxLength 255 */
    name: string;
    durationType: "COUNT_BASED" | "PERIOD_BASED";
    storeId: number | null;
    classType: "GROUP" | "PRIVATE";
    color: string | null;
    count: number;
    cancelableCount: number;
    durationDays: number | null;
    maxCapacity: number | null;
    liked: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
  latestSuspension: {
    id: number;
    membershipId: number;
    startedAt: string;
    endedAt: string;
    createdAt: string;
    updatedAt: string;
  } | null;
}[];

export type ClassListResult = {
  id: number;
  /** @maxLength 255 */
  name: string;
  userCount: number;
  maxCapacity: number;
  type: "GROUP" | "PRIVATE";
  reservationAvailableHour: number | null;
  classCancelHour: number;
  sameDayChangeHour: number | null;
  startedAt: string;
  endedAt: string;
  roomId: number | null;
  adminUserId: number | null;
  isReservationEnabled: boolean;
  groupClassId: number | null;
  storeId: number;
  deletedReason: string | null;
  deletedUserId: number | null;
  openedAt: string | null;
  deletedAt: string | null;
  createdAt: string;
  updatedAt: string;
  adminUser: {
    id: number;
    isActivated: boolean;
    /** @maxLength 255 */
    username: string;
    name: string | null;
    phoneNumber: string | null;
    refreshToken: string | null;
    role: "USER" | "ADMIN";
    adminType: ("INSTRUCTOR" | "MANAGER" | "HEADQUARTERS") | null;
    password: string | null;
    fcmToken: string | null;
    profileImageUrl: string | null;
    color: string | null;
    description: string | null;
    timeTable:
      | {
          isDayOff: boolean;
          open?: string;
          close?: string;
        }[]
      | null;
    isNotification: boolean;
    isLinked: boolean;
    gender: ("MALE" | "FEMALE") | null;
    birth: string | null;
    address: string | null;
    deletedAt: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  room: {
    id: number;
    /** @maxLength 255 */
    name: string;
    storeId: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  reservations: {
    id: number;
    userId: number;
    classId: number;
    status: "RESERVED" | "CANCELED" | "PRESENT" | "NO_SHOW" | "WAITING";
    membershipId: number;
    canceledAt: string | null;
    createdAt: string;
    updatedAt: string;
    user: {
      id: number;
      isActivated: boolean;
      /** @maxLength 255 */
      username: string;
      name: string | null;
      phoneNumber: string | null;
      refreshToken: string | null;
      role: "USER" | "ADMIN";
      adminType: ("INSTRUCTOR" | "MANAGER" | "HEADQUARTERS") | null;
      password: string | null;
      fcmToken: string | null;
      profileImageUrl: string | null;
      color: string | null;
      description: string | null;
      timeTable:
        | {
            isDayOff: boolean;
            open?: string;
            close?: string;
          }[]
        | null;
      isNotification: boolean;
      isLinked: boolean;
      gender: ("MALE" | "FEMALE") | null;
      birth: string | null;
      address: string | null;
      deletedAt: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
  }[];
  classAvailableMemberships: {
    id: number;
    classId: number;
    membershipId: number;
    createdAt: string;
    updatedAt: string;
  }[];
  classAvailablePlans: {
    id: number;
    classId: number;
    planId: number;
    createdAt: string;
    updatedAt: string;
  }[];
}[];

export interface ClassDetailResult {
  id: number;
  /** @maxLength 255 */
  name: string;
  userCount: number;
  maxCapacity: number;
  type: "GROUP" | "PRIVATE";
  reservationAvailableHour: number | null;
  classCancelHour: number;
  sameDayChangeHour: number | null;
  startedAt: string;
  endedAt: string;
  roomId: number | null;
  adminUserId: number | null;
  isReservationEnabled: boolean;
  groupClassId: number | null;
  storeId: number;
  deletedReason: string | null;
  deletedUserId: number | null;
  openedAt: string | null;
  deletedAt: string | null;
  createdAt: string;
  updatedAt: string;
  adminUser: {
    id: number;
    isActivated: boolean;
    /** @maxLength 255 */
    username: string;
    name: string | null;
    phoneNumber: string | null;
    refreshToken: string | null;
    role: "USER" | "ADMIN";
    adminType: ("INSTRUCTOR" | "MANAGER" | "HEADQUARTERS") | null;
    password: string | null;
    fcmToken: string | null;
    profileImageUrl: string | null;
    color: string | null;
    description: string | null;
    timeTable:
      | {
          isDayOff: boolean;
          open?: string;
          close?: string;
        }[]
      | null;
    isNotification: boolean;
    isLinked: boolean;
    gender: ("MALE" | "FEMALE") | null;
    birth: string | null;
    address: string | null;
    deletedAt: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  room: {
    id: number;
    /** @maxLength 255 */
    name: string;
    storeId: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  reservations: {
    id: number;
    userId: number;
    classId: number;
    status: "RESERVED" | "CANCELED" | "PRESENT" | "NO_SHOW" | "WAITING";
    membershipId: number;
    canceledAt: string | null;
    createdAt: string;
    updatedAt: string;
    user: {
      id: number;
      isActivated: boolean;
      /** @maxLength 255 */
      username: string;
      name: string | null;
      phoneNumber: string | null;
      refreshToken: string | null;
      role: "USER" | "ADMIN";
      adminType: ("INSTRUCTOR" | "MANAGER" | "HEADQUARTERS") | null;
      password: string | null;
      fcmToken: string | null;
      profileImageUrl: string | null;
      color: string | null;
      description: string | null;
      timeTable:
        | {
            isDayOff: boolean;
            open?: string;
            close?: string;
          }[]
        | null;
      isNotification: boolean;
      isLinked: boolean;
      gender: ("MALE" | "FEMALE") | null;
      birth: string | null;
      address: string | null;
      deletedAt: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
  }[];
  classAvailableMemberships: {
    id: number;
    classId: number;
    membershipId: number;
    createdAt: string;
    updatedAt: string;
  }[];
  classAvailablePlans: {
    id: number;
    classId: number;
    planId: number;
    createdAt: string;
    updatedAt: string;
  }[];
}

export type StoreListResult = {
  id: number;
  /** @maxLength 255 */
  name: string;
  tel: string | null;
  phoneNumber: string | null;
  type: "GYM" | "PILATES";
  didAccount: string | null;
  address: string | null;
  addressDetail: string | null;
  point: number;
  timeTable:
    | {
        isDayOff: boolean;
        open?: string;
        close?: string;
      }[]
    | null;
  color: string | null;
  createdAt: string;
  updatedAt: string;
}[];

export interface StoreDetailData {
  id: number;
  /** @maxLength 255 */
  name: string;
  tel: string | null;
  phoneNumber: string | null;
  type: "GYM" | "PILATES";
  didAccount: string | null;
  address: string | null;
  addressDetail: string | null;
  point: number;
  timeTable:
    | {
        isDayOff: boolean;
        open?: string;
        close?: string;
      }[]
    | null;
  color: string | null;
  createdAt: string;
  updatedAt: string;
}

export type GetReservationsData = {
  id: number;
  userId: number;
  classId: number;
  status: "RESERVED" | "CANCELED" | "PRESENT" | "NO_SHOW" | "WAITING";
  membershipId: number;
  canceledAt: string | null;
  createdAt: string;
  updatedAt: string;
  class: {
    id: number;
    /** @maxLength 255 */
    name: string;
    userCount: number;
    maxCapacity: number;
    type: "GROUP" | "PRIVATE";
    reservationAvailableHour: number | null;
    classCancelHour: number;
    sameDayChangeHour: number | null;
    startedAt: string;
    endedAt: string;
    roomId: number | null;
    adminUserId: number | null;
    isReservationEnabled: boolean;
    groupClassId: number | null;
    storeId: number;
    deletedReason: string | null;
    deletedUserId: number | null;
    openedAt: string | null;
    deletedAt: string | null;
    createdAt: string;
    updatedAt: string;
    room: {
      id: number;
      /** @maxLength 255 */
      name: string;
      storeId: number | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    adminUser: {
      id: number;
      isActivated: boolean;
      /** @maxLength 255 */
      username: string;
      name: string | null;
      phoneNumber: string | null;
      refreshToken: string | null;
      role: "USER" | "ADMIN";
      adminType: ("INSTRUCTOR" | "MANAGER" | "HEADQUARTERS") | null;
      password: string | null;
      fcmToken: string | null;
      profileImageUrl: string | null;
      color: string | null;
      description: string | null;
      timeTable:
        | {
            isDayOff: boolean;
            open?: string;
            close?: string;
          }[]
        | null;
      isNotification: boolean;
      isLinked: boolean;
      gender: ("MALE" | "FEMALE") | null;
      birth: string | null;
      address: string | null;
      deletedAt: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null;
  membership: {
    id: number;
    userId: number;
    planId: number;
    capacity: number;
    count: number;
    cancelableCount: number;
    startedAt: string;
    endedAt: string;
    createdAt: string;
    updatedAt: string;
    plan: {
      id: number;
      /** @maxLength 255 */
      name: string;
      durationType: "COUNT_BASED" | "PERIOD_BASED";
      storeId: number | null;
      classType: "GROUP" | "PRIVATE";
      color: string | null;
      count: number;
      cancelableCount: number;
      durationDays: number | null;
      maxCapacity: number | null;
      liked: boolean;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null;
}[];

export type MyAllListData = {
  id: number;
  userId: number;
  classId: number;
  status: "RESERVED" | "CANCELED" | "PRESENT" | "NO_SHOW" | "WAITING";
  membershipId: number;
  canceledAt: string | null;
  createdAt: string;
  updatedAt: string;
  class: {
    id: number;
    /** @maxLength 255 */
    name: string;
    userCount: number;
    maxCapacity: number;
    type: "GROUP" | "PRIVATE";
    reservationAvailableHour: number | null;
    classCancelHour: number;
    sameDayChangeHour: number | null;
    startedAt: string;
    endedAt: string;
    roomId: number | null;
    adminUserId: number | null;
    isReservationEnabled: boolean;
    groupClassId: number | null;
    storeId: number;
    deletedReason: string | null;
    deletedUserId: number | null;
    openedAt: string | null;
    deletedAt: string | null;
    createdAt: string;
    updatedAt: string;
    room: {
      id: number;
      /** @maxLength 255 */
      name: string;
      storeId: number | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    adminUser: {
      id: number;
      isActivated: boolean;
      /** @maxLength 255 */
      username: string;
      name: string | null;
      phoneNumber: string | null;
      refreshToken: string | null;
      role: "USER" | "ADMIN";
      adminType: ("INSTRUCTOR" | "MANAGER" | "HEADQUARTERS") | null;
      password: string | null;
      fcmToken: string | null;
      profileImageUrl: string | null;
      color: string | null;
      description: string | null;
      timeTable:
        | {
            isDayOff: boolean;
            open?: string;
            close?: string;
          }[]
        | null;
      isNotification: boolean;
      isLinked: boolean;
      gender: ("MALE" | "FEMALE") | null;
      birth: string | null;
      address: string | null;
      deletedAt: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    store: {
      id: number;
      /** @maxLength 255 */
      name: string;
      tel: string | null;
      phoneNumber: string | null;
      type: "GYM" | "PILATES";
      didAccount: string | null;
      address: string | null;
      addressDetail: string | null;
      point: number;
      timeTable:
        | {
            isDayOff: boolean;
            open?: string;
            close?: string;
          }[]
        | null;
      color: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null;
  membership: {
    id: number;
    userId: number;
    planId: number;
    capacity: number;
    count: number;
    cancelableCount: number;
    startedAt: string;
    endedAt: string;
    createdAt: string;
    updatedAt: string;
    plan: {
      id: number;
      /** @maxLength 255 */
      name: string;
      durationType: "COUNT_BASED" | "PERIOD_BASED";
      storeId: number | null;
      classType: "GROUP" | "PRIVATE";
      color: string | null;
      count: number;
      cancelableCount: number;
      durationDays: number | null;
      maxCapacity: number | null;
      liked: boolean;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null;
}[];

export interface ReservationsCreateData {
  waitingCount?: number;
}

export type CancelCreateData = any;

export type TodayClassesDetailData = {
  id: number;
  /** @maxLength 255 */
  name: string;
  userCount: number;
  maxCapacity: number;
  type: "GROUP" | "PRIVATE";
  reservationAvailableHour: number | null;
  classCancelHour: number;
  sameDayChangeHour: number | null;
  startedAt: string;
  endedAt: string;
  roomId: number | null;
  adminUserId: number | null;
  isReservationEnabled: boolean;
  groupClassId: number | null;
  storeId: number;
  deletedReason: string | null;
  deletedUserId: number | null;
  openedAt: string | null;
  deletedAt: string | null;
  createdAt: string;
  updatedAt: string;
}[];

export type ReservationsDetailData = {
  id: number;
  userId: number;
  classId: number;
  status: "RESERVED" | "CANCELED" | "PRESENT" | "NO_SHOW" | "WAITING";
  membershipId: number;
  canceledAt: string | null;
  createdAt: string;
  updatedAt: string;
  user: {
    id: number;
    name: string | null;
    profileImageUrl: string | null;
  } | null;
}[];

export type RowingHistoriesCreateData = any;

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "http://localhost:8080";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title havenew-crm-backend
 * @version 0.1.0
 * @baseUrl http://localhost:8080
 *
 * 31..
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  auth = {
    /**
     * No description
     *
     * @tags 인증
     * @name LoginCreate
     * @request POST:/auth/login
     * @secure
     */
    loginCreate: (
      data: {
        phoneNumber: string;
        password: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<LoginCreateData, any>({
        path: `/auth/login`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 인증
     * @name SignupVerificationCreate
     * @request POST:/auth/signup/verification
     * @secure
     */
    signupVerificationCreate: (
      data: {
        phoneNumber: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SignupVerificationCreateData, any>({
        path: `/auth/signup/verification`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags 인증
     * @name SignupVerifyCreate
     * @request POST:/auth/signup/verify
     * @secure
     */
    signupVerifyCreate: (
      data: {
        phoneNumber: string;
        code: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SignupVerifyCreateData, any>({
        path: `/auth/signup/verify`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags 인증
     * @name SignupCreate
     * @request POST:/auth/signup
     * @secure
     */
    signupCreate: (
      data: {
        phoneNumber: string;
        password: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SignupCreateData, any>({
        path: `/auth/signup`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags 인증
     * @name ResetPasswordVerificationCreate
     * @request POST:/auth/reset-password/verification
     * @secure
     */
    resetPasswordVerificationCreate: (
      data: {
        phoneNumber: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ResetPasswordVerificationCreateData, any>({
        path: `/auth/reset-password/verification`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags 인증
     * @name ResetPasswordVerifyCreate
     * @request POST:/auth/reset-password/verify
     * @secure
     */
    resetPasswordVerifyCreate: (
      data: {
        phoneNumber: string;
        code: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ResetPasswordVerifyCreateData, any>({
        path: `/auth/reset-password/verify`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags 인증
     * @name ResetPasswordCreate
     * @request POST:/auth/reset-password
     * @secure
     */
    resetPasswordCreate: (
      data: {
        phoneNumber: string;
        code: string;
        newPassword: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ResetPasswordCreateData, any>({
        path: `/auth/reset-password`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  users = {
    /**
     * No description
     *
     * @tags 유저
     * @name GetUsers
     * @request GET:/users/my
     * @secure
     */
    getUsers: (params: RequestParams = {}) =>
      this.request<GetUsersData, any>({
        path: `/users/my`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 유저
     * @name PatchUsers
     * @request PATCH:/users/my
     * @secure
     */
    patchUsers: (
      data: {
        name?: string | null;
        birth?: string | null;
        gender?: ("MALE" | "FEMALE") | null;
        isNotification?: boolean;
        address?: string | null;
        description?: string | null;
        profileImageUrl?: string | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<PatchUsersData, any>({
        path: `/users/my`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags 유저
     * @name StoreDelete
     * @request DELETE:/users/store/{storeId}
     * @secure
     */
    storeDelete: (storeId: number, params: RequestParams = {}) =>
      this.request<StoreDeleteData, any>({
        path: `/users/store/${storeId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags 유저
     * @name AttendanceList
     * @request GET:/users/attendance
     * @secure
     */
    attendanceList: (params: RequestParams = {}) =>
      this.request<AttendanceListData, any>({
        path: `/users/attendance`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 유저
     * @name InbodyHistoriesList
     * @request GET:/users/inbody-histories
     * @secure
     */
    inbodyHistoriesList: (params: RequestParams = {}) =>
      this.request<InbodyHistoriesListData, any>({
        path: `/users/inbody-histories`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 유저
     * @name RowingRankingList
     * @request GET:/users/rowing-ranking
     * @secure
     */
    rowingRankingList: (params: RequestParams = {}) =>
      this.request<RowingRankingListData, any>({
        path: `/users/rowing-ranking`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 유저
     * @name TodayRowingList
     * @request GET:/users/today-rowing
     * @secure
     */
    todayRowingList: (params: RequestParams = {}) =>
      this.request<TodayRowingListData, any>({
        path: `/users/today-rowing`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 유저
     * @name MonthlyPresentRankingList
     * @request GET:/users/monthly-present-ranking
     * @secure
     */
    monthlyPresentRankingList: (params: RequestParams = {}) =>
      this.request<MonthlyPresentRankingListData, any>({
        path: `/users/monthly-present-ranking`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 유저
     * @name AchievementsList
     * @request GET:/users/achievements
     * @secure
     */
    achievementsList: (params: RequestParams = {}) =>
      this.request<AchievementsListData, any>({
        path: `/users/achievements`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  admin = {
    /**
     * No description
     *
     * @tags 인증
     * @name AuthSigninCreate
     * @request POST:/admin/auth/signin
     * @secure
     */
    authSigninCreate: (
      data: {
        username: string;
        password: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<AuthSigninCreateData, any>({
        path: `/admin/auth/signin`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 인증
     * @name AuthRefreshCreate
     * @request POST:/admin/auth/refresh
     * @secure
     */
    authRefreshCreate: (
      data: {
        refreshToken: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<AuthRefreshCreateData, any>({
        path: `/admin/auth/refresh`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 인증
     * @name AuthUserList
     * @request GET:/admin/auth/user
     * @secure
     */
    authUserList: (
      query: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<AuthUserListData, any>({
        path: `/admin/auth/user`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 인증
     * @name AuthSendUsernameCreate
     * @request POST:/admin/auth/send-username
     * @secure
     */
    authSendUsernameCreate: (
      data: {
        phoneNumber: string;
        username: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<AuthSendUsernameCreateData, any>({
        path: `/admin/auth/send-username`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags 인증
     * @name AuthSendResetCreate
     * @request POST:/admin/auth/send-reset
     * @secure
     */
    authSendResetCreate: (
      data: {
        username: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<AuthSendResetCreateData, any>({
        path: `/admin/auth/send-reset`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags 어드민 유저
     * @name UserMeList
     * @request GET:/admin/user/me
     * @secure
     */
    userMeList: (params: RequestParams = {}) =>
      this.request<UserMeListData, any>({
        path: `/admin/user/me`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 어드민 유저
     * @name UserStudentsList
     * @request GET:/admin/user/students
     * @secure
     */
    userStudentsList: (params: RequestParams = {}) =>
      this.request<UserStudentsListData, any>({
        path: `/admin/user/students`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 어드민 유저
     * @name UserCreate
     * @request POST:/admin/user/
     * @secure
     */
    userCreate: (
      data: {
        /** @maxLength 255 */
        username: string;
        name?: string | null;
        phoneNumber?: string | null;
        profileImageUrl?: string | null;
        color?: string | null;
        description?: string | null;
        timeTable: (
          | {
              isDayOff: boolean;
              open?: string;
              close?: string;
            }[]
          | null
        ) &
          (
            | {
                isDayOff: boolean;
                open?: string;
                close?: string;
                description?: string;
              }[]
            | null
          );
        isNotification?: boolean;
        isLinked?: boolean;
        gender?: ("MALE" | "FEMALE") | null;
        birth?: string | null;
        address?: string | null;
        deletedAt?: string | null;
        updatedAt?: string;
        adminType: "INSTRUCTOR" | null;
        role: "ADMIN" | "USER";
      },
      params: RequestParams = {},
    ) =>
      this.request<UserCreateData, any>({
        path: `/admin/user/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 어드민 유저
     * @name UserPartialUpdate
     * @request PATCH:/admin/user/{id}
     * @secure
     */
    userPartialUpdate: (
      id: number,
      data: {
        /** @maxLength 255 */
        username: string;
        name?: string | null;
        phoneNumber?: string | null;
        profileImageUrl?: string | null;
        color?: string | null;
        description?: string | null;
        timeTable: (
          | {
              isDayOff: boolean;
              open?: string;
              close?: string;
            }[]
          | null
        ) &
          (
            | {
                isDayOff: boolean;
                open?: string;
                close?: string;
                description?: string;
              }[]
            | null
          );
        isNotification?: boolean;
        isLinked?: boolean;
        gender?: ("MALE" | "FEMALE") | null;
        birth?: string | null;
        address?: string | null;
        deletedAt?: string | null;
        updatedAt?: string;
        adminType: "INSTRUCTOR" | null;
        role: "ADMIN" | "USER";
      },
      params: RequestParams = {},
    ) =>
      this.request<UserPartialUpdateData, any>({
        path: `/admin/user/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags 어드민 유저
     * @name UserDetail
     * @request GET:/admin/user/{id}
     * @secure
     */
    userDetail: (id: number, params: RequestParams = {}) =>
      this.request<UserDetailData, any>({
        path: `/admin/user/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 어드민 유저
     * @name UserClassesDetail
     * @request GET:/admin/user/{id}/classes
     * @secure
     */
    userClassesDetail: (id: number, params: RequestParams = {}) =>
      this.request<UserClassesDetailData, any>({
        path: `/admin/user/${id}/classes`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 어드민 유저
     * @name UserStudentsDetail
     * @request GET:/admin/user/{id}/students
     * @secure
     */
    userStudentsDetail: (id: number, params: RequestParams = {}) =>
      this.request<UserStudentsDetailData, any>({
        path: `/admin/user/${id}/students`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 어드민 유저
     * @name UserReservationCountDetail
     * @request GET:/admin/user/{id}/reservation-count
     * @secure
     */
    userReservationCountDetail: (id: number, params: RequestParams = {}) =>
      this.request<UserReservationCountDetailData, any>({
        path: `/admin/user/${id}/reservation-count`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 어드민 유저
     * @name UserInbodyHistoriesList
     * @request GET:/admin/user/inbody-histories
     * @secure
     */
    userInbodyHistoriesList: (params: RequestParams = {}) =>
      this.request<UserInbodyHistoriesListData, any>({
        path: `/admin/user/inbody-histories`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 어드민 유저
     * @name UserInbodyHistoriesDetail
     * @request GET:/admin/user/{id}/inbody-histories
     * @secure
     */
    userInbodyHistoriesDetail: (id: number, params: RequestParams = {}) =>
      this.request<UserInbodyHistoriesDetailData, any>({
        path: `/admin/user/${id}/inbody-histories`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 어드민 유저
     * @name UserMembershipHistoriesList
     * @request GET:/admin/user/membership-histories
     * @secure
     */
    userMembershipHistoriesList: (params: RequestParams = {}) =>
      this.request<UserMembershipHistoriesListData, any>({
        path: `/admin/user/membership-histories`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 어드민 유저
     * @name UserMembershipHistoriesDetail
     * @request GET:/admin/user/{id}/membership-histories
     * @secure
     */
    userMembershipHistoriesDetail: (id: number, params: RequestParams = {}) =>
      this.request<UserMembershipHistoriesDetailData, any>({
        path: `/admin/user/${id}/membership-histories`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 어드민 유저
     * @name UserMemosDetail
     * @request GET:/admin/user/{id}/memos
     * @secure
     */
    userMemosDetail: (id: number, params: RequestParams = {}) =>
      this.request<UserMemosDetailData, any>({
        path: `/admin/user/${id}/memos`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 어드민 유저
     * @name UserMemosCreate
     * @request POST:/admin/user/{id}/memos
     * @secure
     */
    userMemosCreate: (
      id: number,
      data: {
        content: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserMemosCreateData, any>({
        path: `/admin/user/${id}/memos`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags 어드민 유저
     * @name UserMemosDelete
     * @request DELETE:/admin/user/{id}/memos/{memoId}
     * @secure
     */
    userMemosDelete: (id: number, memoId: number, params: RequestParams = {}) =>
      this.request<UserMemosDeleteData, any>({
        path: `/admin/user/${id}/memos/${memoId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags 어드민 유저
     * @name UserMemosPartialUpdate
     * @request PATCH:/admin/user/{id}/memos/{memoId}
     * @secure
     */
    userMemosPartialUpdate: (
      id: number,
      memoId: number,
      data: {
        content: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserMemosPartialUpdateData, any>({
        path: `/admin/user/${id}/memos/${memoId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags 어드민 유저
     * @name UserMembershipsDetail
     * @request GET:/admin/user/{id}/memberships
     * @secure
     */
    userMembershipsDetail: (id: number, params: RequestParams = {}) =>
      this.request<UserMembershipsDetailData, any>({
        path: `/admin/user/${id}/memberships`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 어드민 유저
     * @name UserMembershipsCreate
     * @request POST:/admin/user/{id}/memberships
     * @secure
     */
    userMembershipsCreate: (
      id: number,
      data: {
        planId: number;
        startedAt: string;
        endedAt: string;
        count: number;
        capacity: number;
        membershipInstructorIds: number[];
      },
      params: RequestParams = {},
    ) =>
      this.request<UserMembershipsCreateData, any>({
        path: `/admin/user/${id}/memberships`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags 어드민 유저
     * @name UserMembershipsPartialUpdate
     * @request PATCH:/admin/user/{id}/memberships/{membershipId}
     * @secure
     */
    userMembershipsPartialUpdate: (
      id: number,
      membershipId: number,
      data: {
        planId: number;
        startedAt: string;
        endedAt: string;
        count: number;
        capacity: number;
        membershipInstructorIds: number[];
        reason: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserMembershipsPartialUpdateData, any>({
        path: `/admin/user/${id}/memberships/${membershipId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags 어드민 유저
     * @name UserExtendCreate
     * @request POST:/admin/user/extend
     * @secure
     */
    userExtendCreate: (
      data: {
        userIds: number[];
        baseDate: string;
        extendDays: number;
        extendCount: number;
        reason: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserExtendCreateData, any>({
        path: `/admin/user/extend`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags 어드민 유저
     * @name UserSuspendCreate
     * @request POST:/admin/user/suspend
     * @secure
     */
    userSuspendCreate: (
      data: {
        userIds: number[];
        startedAt: string;
        endedAt: string;
        reason: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserSuspendCreateData, any>({
        path: `/admin/user/suspend`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags 어드민 유저
     * @name UserInstructorsList
     * @request GET:/admin/user/instructors
     * @secure
     */
    userInstructorsList: (params: RequestParams = {}) =>
      this.request<UserInstructorsListData, any>({
        path: `/admin/user/instructors`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name StoreList
     * @request GET:/admin/store/
     * @secure
     */
    storeList: (params: RequestParams = {}) =>
      this.request<StoreListData, any>({
        path: `/admin/store/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name StoreCreate
     * @request POST:/admin/store/
     * @secure
     */
    storeCreate: (
      data: {
        id?: number;
        /** @maxLength 255 */
        name: string;
        tel?: string | null;
        phoneNumber?: string | null;
        type: "GYM" | "PILATES";
        didAccount?: string | null;
        address?: string | null;
        addressDetail?: string | null;
        point?: number;
        timeTable?:
          | {
              isDayOff: boolean;
              open?: string;
              close?: string;
            }[]
          | null;
        color?: string | null;
        createdAt?: string;
        updatedAt?: string;
        adminName: string;
        adminPhoneNumber: string;
        username: string;
        password: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<StoreCreateData, any>({
        path: `/admin/store/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name StoreMyList
     * @request GET:/admin/store/my
     * @secure
     */
    storeMyList: (params: RequestParams = {}) =>
      this.request<StoreMyListData, any>({
        path: `/admin/store/my`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name StoreStoreDelete
     * @request DELETE:/admin/store/store/{id}
     * @secure
     */
    storeStoreDelete: (id: number, params: RequestParams = {}) =>
      this.request<StoreStoreDeleteData, any>({
        path: `/admin/store/store/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name StoreSettingsList
     * @request GET:/admin/store/settings
     * @secure
     */
    storeSettingsList: (params: RequestParams = {}) =>
      this.request<StoreSettingsListData, any>({
        path: `/admin/store/settings`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name StoreSettingsCreate
     * @request POST:/admin/store/settings
     * @secure
     */
    storeSettingsCreate: (
      data: {
        id?: number;
        storeId: number;
        reservationAvailableHour: number;
        groupClassCancelHour: number;
        privateClassCancelHour: number;
        sameDayChangeHour: number;
        waitingAutoReserveHour: number;
        dailyReservationLimit: number;
        checkInScreenImage?: string | null;
        createdAt?: string;
        updatedAt?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<StoreSettingsCreateData, any>({
        path: `/admin/store/settings`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name StorePermissionsList
     * @request GET:/admin/store/permissions
     * @secure
     */
    storePermissionsList: (params: RequestParams = {}) =>
      this.request<StorePermissionsListData, any>({
        path: `/admin/store/permissions`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name StoreStorePermissionsCreate
     * @request POST:/admin/store/store/permissions
     * @secure
     */
    storeStorePermissionsCreate: (
      data: {
        id?: number;
        instructorGroupClassModifyYn?: boolean;
        instructorPrivateClassCreateYn?: boolean;
        instructorPrivateClassModifyYn?: boolean;
        instructorPrivateClassReserveModifyYn?: boolean;
        instructorPrivateClassDeleteYn?: boolean;
        otherInstructorGroupClassViewYn?: boolean;
        otherInstructorPrivateClassViewYn?: boolean;
        memberListViewYn?: boolean;
        memberCreateYn?: boolean;
        memberTicketModifyYn?: boolean;
        instructorListViewYn?: boolean;
        ticketCreateYn?: boolean;
        ticketModifyYn?: boolean;
        operationSettingYn?: boolean;
        createdAt?: string;
        updatedAt?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<StoreStorePermissionsCreateData, any>({
        path: `/admin/store/store/permissions`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name StoreNotificationsList
     * @request GET:/admin/store/notifications
     * @secure
     */
    storeNotificationsList: (params: RequestParams = {}) =>
      this.request<StoreNotificationsListData, any>({
        path: `/admin/store/notifications`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name StoreNotificationsCreate
     * @request POST:/admin/store/notifications
     * @secure
     */
    storeNotificationsCreate: (
      data: {
        id?: number;
        storeId: number;
        ticketExpireDays: number;
        ticketExpireTemplate: string;
        ticketExpireNotificationType: "PUSH" | "SMS";
        ticketRemainCount: number;
        ticketRemainTemplate: string;
        ticketRemainNotificationType: "PUSH" | "SMS";
        pausePeriodExpireDays: number;
        pausePeriodExpireTemplate: string;
        waitingToReserveTemplate: string;
        waitingToReserveNotificationType: "PUSH" | "SMS";
        groupClassStartHour: number;
        groupClassStartTemplate: string;
        groupClassStartNotificationType: "PUSH" | "SMS";
        privateClassStartHour: number;
        privateClassStartTemplate: string;
        privateClassStartNotificationType: "PUSH" | "SMS";
        birthdayTemplate: string;
        birthdayNotificationType: "PUSH" | "SMS";
        createdAt?: string;
        updatedAt?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<StoreNotificationsCreateData, any>({
        path: `/admin/store/notifications`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name StoreRoomsList
     * @request GET:/admin/store/rooms
     * @secure
     */
    storeRoomsList: (params: RequestParams = {}) =>
      this.request<StoreRoomsListData, any>({
        path: `/admin/store/rooms`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name StoreRoomsCreate
     * @request POST:/admin/store/rooms
     * @secure
     */
    storeRoomsCreate: (
      data: {
        id?: number;
        /** @maxLength 255 */
        name: string;
        storeId?: number | null;
        createdAt?: string;
        updatedAt?: string;
      }[],
      params: RequestParams = {},
    ) =>
      this.request<StoreRoomsCreateData, any>({
        path: `/admin/store/rooms`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags
     * @name MembershipList
     * @request GET:/admin/membership/
     * @secure
     */
    membershipList: (params: RequestParams = {}) =>
      this.request<MembershipListData, any>({
        path: `/admin/membership/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags
     * @name MembershipCreate
     * @request POST:/admin/membership/
     * @secure
     */
    membershipCreate: (
      data: {
        id?: number;
        userId: number;
        planId: number;
        capacity: number;
        count: number;
        cancelableCount: number;
        startedAt: string;
        endedAt: string;
        createdAt?: string;
        updatedAt?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<MembershipCreateData, any>({
        path: `/admin/membership/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags
     * @name MembershipPlanList
     * @request GET:/admin/membershipPlan/
     * @secure
     */
    membershipPlanList: (params: RequestParams = {}) =>
      this.request<MembershipPlanListData, any>({
        path: `/admin/membershipPlan/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags
     * @name MembershipPlanCreate
     * @request POST:/admin/membershipPlan/
     * @secure
     */
    membershipPlanCreate: (
      data: {
        id?: number;
        /** @maxLength 255 */
        name: string;
        durationType: "COUNT_BASED" | "PERIOD_BASED";
        storeId?: number | null;
        classType: "GROUP" | "PRIVATE";
        color?: string | null;
        count: number;
        cancelableCount: number;
        durationDays?: number | null;
        maxCapacity?: number | null;
        liked?: boolean;
        createdAt?: string;
        updatedAt?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<MembershipPlanCreateData, any>({
        path: `/admin/membershipPlan/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags
     * @name MembershipPlanDetail
     * @request GET:/admin/membershipPlan/{id}
     * @secure
     */
    membershipPlanDetail: (id: number, params: RequestParams = {}) =>
      this.request<MembershipPlanDetailData, any>({
        path: `/admin/membershipPlan/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags
     * @name MembershipPlanMembershipsDetail
     * @request GET:/admin/membershipPlan/{id}/memberships
     * @secure
     */
    membershipPlanMembershipsDetail: (id: number, params: RequestParams = {}) =>
      this.request<MembershipPlanMembershipsDetailData, any>({
        path: `/admin/membershipPlan/${id}/memberships`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name ClassList
     * @request GET:/admin/class/
     * @secure
     */
    classList: (params: RequestParams = {}) =>
      this.request<ClassListData, any>({
        path: `/admin/class/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name ClassBulkOpenCreate
     * @request POST:/admin/class/bulk-open
     * @secure
     */
    classBulkOpenCreate: (
      data: {
        classIds: number[];
        opendedAt: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ClassBulkOpenCreateData, any>({
        path: `/admin/class/bulk-open`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name ClassBulkDeleteDelete
     * @request DELETE:/admin/class/bulk-delete
     * @secure
     */
    classBulkDeleteDelete: (
      data: {
        classIds: number[];
      },
      params: RequestParams = {},
    ) =>
      this.request<ClassBulkDeleteDeleteData, any>({
        path: `/admin/class/bulk-delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name ClassBulkUpdateCreate
     * @request POST:/admin/class/bulk-update
     * @secure
     */
    classBulkUpdateCreate: (
      data: {
        name?: string;
        maxCapacity?: number;
        adminUserId?: number;
        roomId?: number;
        reservationAvailableHour?: number;
        classCancelHour?: number;
        sameDayChangeHour?: number;
        classIds: number[];
      },
      params: RequestParams = {},
    ) =>
      this.request<ClassBulkUpdateCreateData, any>({
        path: `/admin/class/bulk-update`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name ClassReservationsList
     * @request GET:/admin/class/reservations
     * @secure
     */
    classReservationsList: (params: RequestParams = {}) =>
      this.request<ClassReservationsListData, any>({
        path: `/admin/class/reservations`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name ClassDeletedList
     * @request GET:/admin/class/deleted
     * @secure
     */
    classDeletedList: (params: RequestParams = {}) =>
      this.request<ClassDeletedListData, any>({
        path: `/admin/class/deleted`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name ClassDetail
     * @request GET:/admin/class/{classId}
     * @secure
     */
    classDetail: (classId: number, params: RequestParams = {}) =>
      this.request<ClassDetailData, any>({
        path: `/admin/class/${classId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name ClassDelete
     * @request DELETE:/admin/class/{classId}
     * @secure
     */
    classDelete: (classId: number, params: RequestParams = {}) =>
      this.request<ClassDeleteData, any>({
        path: `/admin/class/${classId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name ClassPartialUpdate
     * @request PATCH:/admin/class/{classId}
     * @secure
     */
    classPartialUpdate: (
      classId: number,
      data: {
        id?: number;
        /** @maxLength 255 */
        name?: string;
        userCount?: number;
        maxCapacity?: number;
        type?: "GROUP" | "PRIVATE";
        reservationAvailableHour?: number | null;
        classCancelHour?: number;
        sameDayChangeHour?: number | null;
        startedAt?: string;
        endedAt?: string;
        roomId?: ((number | null) | null) & (number | null);
        adminUserId?: number | null;
        isReservationEnabled?: boolean;
        groupClassId?: number | null;
        storeId?: number;
        deletedReason?: string | null;
        deletedUserId?: number | null;
        openedAt?: string | null;
        deletedAt?: string | null;
        createdAt?: string;
        updatedAt?: string;
        classAvailablePlanIds?: number[];
      },
      params: RequestParams = {},
    ) =>
      this.request<ClassPartialUpdateData, any>({
        path: `/admin/class/${classId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name ClassPrivateCreate
     * @request POST:/admin/class/private
     * @secure
     */
    classPrivateCreate: (
      data: {
        id?: number;
        /** @maxLength 255 */
        name: string;
        userCount?: number;
        maxCapacity: number;
        reservationAvailableHour?: number | null;
        classCancelHour: number;
        sameDayChangeHour?: number | null;
        startedAt: string;
        endedAt: string;
        roomId?: (number | null) | null;
        adminUserId?: number | null;
        isReservationEnabled?: boolean;
        groupClassId?: number | null;
        deletedReason?: string | null;
        deletedUserId?: number | null;
        openedAt?: string | null;
        deletedAt?: string | null;
        createdAt?: string;
        updatedAt?: string;
        classAvailableMembershipIds?: number[];
      },
      params: RequestParams = {},
    ) =>
      this.request<ClassPrivateCreateData, any>({
        path: `/admin/class/private`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name ClassMemosDetail
     * @request GET:/admin/class/{id}/memos
     * @secure
     */
    classMemosDetail: (id: number, params: RequestParams = {}) =>
      this.request<ClassMemosDetailData, any>({
        path: `/admin/class/${id}/memos`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name ClassMemosCreate
     * @request POST:/admin/class/{id}/memos
     * @secure
     */
    classMemosCreate: (
      id: number,
      data: {
        content: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ClassMemosCreateData, any>({
        path: `/admin/class/${id}/memos`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name ClassMemosDelete
     * @request DELETE:/admin/class/{id}/memos/{memoId}
     * @secure
     */
    classMemosDelete: (id: number, memoId: number, params: RequestParams = {}) =>
      this.request<ClassMemosDeleteData, any>({
        path: `/admin/class/${id}/memos/${memoId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name ClassMemosPartialUpdate
     * @request PATCH:/admin/class/{id}/memos/{memoId}
     * @secure
     */
    classMemosPartialUpdate: (
      id: number,
      memoId: number,
      data: {
        content: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ClassMemosPartialUpdateData, any>({
        path: `/admin/class/${id}/memos/${memoId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags
     * @name GroupClassCreate
     * @request POST:/admin/group-class/
     * @secure
     */
    groupClassCreate: (
      data: {
        id?: number;
        /** @maxLength 255 */
        name: string;
        maxCapacity: number;
        reservationAvailableHour?: number | null;
        classCancelHour: number;
        sameDayChangeHour?: number | null;
        startedAt: string;
        endedAt: string;
        timeTable: {
          day: number;
          start: string;
          end: string;
          adminUserId: number;
          roomId: number | null;
        }[];
        openedAt?: string | null;
        createdAt?: string;
        updatedAt?: string;
        classAvailablePlanIds: number[];
      },
      params: RequestParams = {},
    ) =>
      this.request<GroupClassCreateData, any>({
        path: `/admin/group-class/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags
     * @name GroupClassPartialUpdate
     * @request PATCH:/admin/group-class/{id}
     * @secure
     */
    groupClassPartialUpdate: (
      id: number,
      data: {
        /** @maxLength 255 */
        name: string;
        maxCapacity: number;
        reservationAvailableHour?: number | null;
        classCancelHour: number;
        sameDayChangeHour?: number | null;
        classAvailablePlanIds?: number[];
      },
      params: RequestParams = {},
    ) =>
      this.request<GroupClassPartialUpdateData, any>({
        path: `/admin/group-class/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags
     * @name PaymentAuthCallbackCreate
     * @request POST:/admin/payment/auth/callback
     * @secure
     */
    paymentAuthCallbackCreate: (
      data: {
        AuthResultCode: string;
        AuthResultMsg: string;
        TxTid: string;
        AuthToken: string;
        PayMethod: string;
        MID: string;
        Moid: string;
        Amt: string;
        ReqReserved: string;
        NextAppURL: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaymentAuthCallbackCreateData, PaymentAuthCallbackCreateError>({
        path: `/admin/payment/auth/callback`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags
     * @name MessagesPurchaseCreate
     * @request POST:/admin/messages/purchase
     * @secure
     */
    messagesPurchaseCreate: (
      data: {
        userId: string;
        storeId: string;
        paymentType: "CARD" | "KAKAO" | "NAVER";
        redirectUrl: string;
        errorRedirectUrl: string;
        purchasePoint: "10000" | "50000" | "100000";
      },
      params: RequestParams = {},
    ) =>
      this.request<MessagesPurchaseCreateData, any>({
        path: `/admin/messages/purchase`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags
     * @name MessagesPurchaseBillingCreate
     * @request POST:/admin/messages/purchase/billing
     * @secure
     */
    messagesPurchaseBillingCreate: (
      data: {
        billingId: number;
        purchasePoint: "10000" | "50000" | "100000";
        redirectUrl: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<MessagesPurchaseBillingCreateData, any>({
        path: `/admin/messages/purchase/billing`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags
     * @name MessagesList
     * @request GET:/admin/messages
     * @secure
     */
    messagesList: (params: RequestParams = {}) =>
      this.request<MessagesListData, any>({
        path: `/admin/messages`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags
     * @name MessagesCreate
     * @request POST:/admin/messages
     * @secure
     */
    messagesCreate: (
      data: {
        id: number;
        senderId: number;
        storeId: number | null;
        title: string | null;
        message: string;
        scheduleAt: string | null;
        imageUrls: string[] | null;
        usingPoint: number;
        createdAt: string;
        updatedAt: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<MessagesCreateData, any>({
        path: `/admin/messages`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags
     * @name MessagesPointPaymentHistoriesList
     * @request GET:/admin/messages/point-payment-histories
     * @secure
     */
    messagesPointPaymentHistoriesList: (params: RequestParams = {}) =>
      this.request<MessagesPointPaymentHistoriesListData, any>({
        path: `/admin/messages/point-payment-histories`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags
     * @name BillingsRegisterCreate
     * @request POST:/admin/billings/register
     * @secure
     */
    billingsRegisterCreate: (
      data: {
        email: string;
        name: string;
        phoneNumber: string;
        redirectUrl: string;
        storeId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BillingsRegisterCreateData, any>({
        path: `/admin/billings/register`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags
     * @name BillingsUnauthRegisterCreate
     * @request POST:/admin/billings/unauth/register
     * @secure
     */
    billingsUnauthRegisterCreate: (
      data: {
        cardNumber: string;
        cardYear: string;
        cardMonth: string;
        cardPassword: string;
        businessNumber: string;
        email: string;
        name: string;
        phoneNumber: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BillingsUnauthRegisterCreateData, any>({
        path: `/admin/billings/unauth/register`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags
     * @name BillingsAuthCallbackCreate
     * @request POST:/admin/billings/auth/callback
     * @secure
     */
    billingsAuthCallbackCreate: (
      data: {
        AuthResultCode: string;
        AuthResultMsg: string;
        TxTid: string;
        AuthToken: string;
        PayMethod: string;
        MID: string;
        Moid: string;
        Amt: string;
        ReqReserved: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, BillingsAuthCallbackCreateError>({
        path: `/admin/billings/auth/callback`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags
     * @name BillingsDelete
     * @request DELETE:/admin/billings
     * @secure
     */
    billingsDelete: (
      data: {
        billingId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<BillingsDeleteData, any>({
        path: `/admin/billings`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags
     * @name BillingsList
     * @request GET:/admin/billings
     * @secure
     */
    billingsList: (params: RequestParams = {}) =>
      this.request<BillingsListData, any>({
        path: `/admin/billings`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags
     * @name ReservationsPartialUpdate
     * @request PATCH:/admin/reservations/{id}
     * @secure
     */
    reservationsPartialUpdate: (
      id: number,
      data: {
        status: "RESERVED" | "CANCELED" | "PRESENT" | "NO_SHOW" | "WAITING";
      },
      params: RequestParams = {},
    ) =>
      this.request<ReservationsPartialUpdateData, any>({
        path: `/admin/reservations/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags
     * @name ReservationsDelete
     * @request DELETE:/admin/reservations/{id}
     * @secure
     */
    reservationsDelete: (id: number, params: RequestParams = {}) =>
      this.request<ReservationsDeleteData, any>({
        path: `/admin/reservations/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags
     * @name ReservationsUserReservationsList
     * @request GET:/admin/reservations/user-reservations
     * @secure
     */
    reservationsUserReservationsList: (
      query: {
        phoneNumber: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ReservationsUserReservationsListData, any>({
        path: `/admin/reservations/user-reservations`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags
     * @name ReservationsPresentCreate
     * @request POST:/admin/reservations/present
     * @secure
     */
    reservationsPresentCreate: (
      data: {
        phoneNumber: string;
        reservationId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ReservationsPresentCreateData, any>({
        path: `/admin/reservations/present`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  memberships = {
    /**
     * No description
     *
     * @tags
     * @name GetMemberships
     * @request GET:/memberships/my
     * @secure
     */
    getMemberships: (params: RequestParams = {}) =>
      this.request<GetMembershipsData, any>({
        path: `/memberships/my`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  class = {
    /**
     * No description
     *
     * @tags
     * @name ClassList
     * @request GET:/class/
     * @secure
     */
    classList: (params: RequestParams = {}) =>
      this.request<ClassListResult, any>({
        path: `/class/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags
     * @name ClassDetail
     * @request GET:/class/{id}
     * @secure
     */
    classDetail: (id: number, params: RequestParams = {}) =>
      this.request<ClassDetailResult, any>({
        path: `/class/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  store = {
    /**
     * No description
     *
     * @tags
     * @name StoreList
     * @request GET:/store/
     * @secure
     */
    storeList: (params: RequestParams = {}) =>
      this.request<StoreListResult, any>({
        path: `/store/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags
     * @name StoreDetail
     * @request GET:/store/{id}
     * @secure
     */
    storeDetail: (id: number, params: RequestParams = {}) =>
      this.request<StoreDetailData, any>({
        path: `/store/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  reservations = {
    /**
     * No description
     *
     * @tags
     * @name GetReservations
     * @request GET:/reservations/my
     * @secure
     */
    getReservations: (params: RequestParams = {}) =>
      this.request<GetReservationsData, any>({
        path: `/reservations/my`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags
     * @name MyAllList
     * @request GET:/reservations/my-all
     * @secure
     */
    myAllList: (params: RequestParams = {}) =>
      this.request<MyAllListData, any>({
        path: `/reservations/my-all`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags
     * @name ReservationsCreate
     * @request POST:/reservations/
     * @secure
     */
    reservationsCreate: (
      data: {
        classId: number;
        membershipId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ReservationsCreateData, any>({
        path: `/reservations/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags
     * @name CancelCreate
     * @request POST:/reservations/{id}/cancel
     * @secure
     */
    cancelCreate: (id: number, params: RequestParams = {}) =>
      this.request<CancelCreateData, any>({
        path: `/reservations/${id}/cancel`,
        method: "POST",
        secure: true,
        ...params,
      }),
  };
  fits = {
    /**
     * No description
     *
     * @tags
     * @name TodayClassesDetail
     * @request GET:/fits/today-classes/{username}
     * @secure
     */
    todayClassesDetail: (username: string, params: RequestParams = {}) =>
      this.request<TodayClassesDetailData, any>({
        path: `/fits/today-classes/${username}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags
     * @name ReservationsDetail
     * @request GET:/fits/reservations/{classId}
     * @secure
     */
    reservationsDetail: (classId: number, params: RequestParams = {}) =>
      this.request<ReservationsDetailData, any>({
        path: `/fits/reservations/${classId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags
     * @name RowingHistoriesCreate
     * @request POST:/fits/rowing-histories
     * @secure
     */
    rowingHistoriesCreate: (
      data: {
        rank: number;
        distance: number;
        time: number;
        userIds: number[];
        classId: number;
        groupNumber: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<RowingHistoriesCreateData, any>({
        path: `/fits/rowing-histories`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
}

import React from "react";
import { useAuthContext } from "../../context/auth";

function PortraitProgramLayout({
  children,
  hiddenTitle,
}: {
  children: React.ReactNode;
  hiddenTitle?: boolean;
}) {
  const { circuitProgram, weightProgram } = useAuthContext();
  return (
    <div className="flex flex-col h-full w-full">
      <div className="flex h-[160px] justify-between px-[70px] items-center bg-bg border-b border-border">
        {!hiddenTitle && (
          <h1 className="text-[64px] font-bold text-white">
            {circuitProgram ? "써킷" : weightProgram ? "웨이트" : "프로그램"}
          </h1>
        )}
        <img className="w-[210px] h-[103px]" src="/logo.png" alt="logo" />
      </div>
      <div className="flex-1">{children}</div>
      <div
        className="h-[64px] flex items-center justify-center"
        style={{
          background:
            "var(--GMOHOB2, linear-gradient(135deg, #E3FAFF 0%, #53CAF5 100%))",
        }}
      >
        <p className="text-[#48505F] text-[32px]">Premium Training Place</p>
      </div>
    </div>
  );
}

export default PortraitProgramLayout;

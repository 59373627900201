import React, { useEffect } from "react";
import LandscapeProgramLayout from "../LandscapeProgramLayout";
import { useAuthContext } from "../../../context/auth";
import api from "../../../lib/api";
import { ReservationsDetailData } from "../../../Api";
import cn from "../../../utils/cn";

function Start() {
  const [reservations, setReservations] =
    React.useState<ReservationsDetailData>([]);
  const {
    circuitProgram,
    weightProgram,
    sendStartTimer,
    sendSetStep,
    classId,
  } = useAuthContext();
  useEffect(() => {
    const getReservations = async () => {
      if (classId) {
        api.fits.reservationsDetail(classId).then((res) => {
          setReservations(res.data);
        });
      }
    };
    getReservations();
    const interval = setInterval(() => {
      getReservations();
    }, 5000);
    return () => clearInterval(interval);
  }, [classId]);

  const program = circuitProgram ?? weightProgram;
  return (
    <LandscapeProgramLayout step={1}>
      <div className="w-full flex-1 flex flex-col justify-center items-center">
        <h1 className="text-[160px] text-white mt-[150px]">{program?.name}</h1>
        <button
          className="rounded-[32px] text-[100px] text-black py-4 px-[160px] flex justify-center items-center mt-[60px]"
          style={{
            background:
              "linear-gradient(270deg, #7DE9FF 0%, #7DE9FF 0.01%, #53CAF5 100%)",
          }}
          onClick={() => {
            sendStartTimer();
          }}
        >
          START
        </button>
        <button
          className="text-[40px] text-white mt-[30px]"
          onClick={() => {
            sendSetStep(0);
          }}
        >
          나가기
        </button>
        <div className="flex-1" />
        <div className="mt-4 flex w-full py-[60px] px-[80px] gap-y-[30px] gap-x-[60px] flex-wrap bg-[#ffffff1f] h-[360px]">
          {reservations.map((reservation, index) => (
            <div
              key={index}
              className={cn(
                "flex items-center gap-[20px]",
                reservation.status === "RESERVED" && "opacity-20"
              )}
            >
              <img
                src={reservation.user?.profileImageUrl ?? "/"}
                alt="profile"
                className="w-[60px] h-[60px] rounded-full"
              />
              <h2 className="text-[30px] text-white">
                {reservation.user?.name}
              </h2>
            </div>
          ))}
        </div>
      </div>
    </LandscapeProgramLayout>
  );
}

export default Start;
